import { ChangeEvent } from "react";
import { Divider, Grid, InputAdornment, MenuItem } from "@mui/material";
import { useContextComissoes } from "src/Contexts/comissoes/ComissoesContext";
import { ICreateCommissions } from "src/Contexts/comissoes/ComissoesTypes";
import { AppTextField } from "src/Pages/components/AppFormComponents/AppTextField";

import { HttpGetOperatorsAll } from "src/shared/Hooks/Https/HttpGetOperatorsAll";
import { HttpGetModalitiesAll } from "src/shared/Hooks/Https/HttpGetModalitiesAll";
import { HttpGetAdministradorasAll } from "src/shared/Hooks/Https/HttpGetAdministratorsAll";
import { HttpGetCompaniesAll } from "src/shared/Hooks/Https/HttpGetCompaniesAll";

export const FormCommissions = () => {
  const { valuesInputsCommissions, setValuesInputsCommissions } =
    useContextComissoes();

  const { data: resultListOperators } = HttpGetOperatorsAll();
  const { data: resultListCompanies } = HttpGetCompaniesAll();
  const { data: resultListModalities } = HttpGetModalitiesAll();
  const { data: resultListAdministrators } = HttpGetAdministradorasAll();

  const updatedResultListCompanies = [
    { code: 0, name: "Nenhum" },
    ...resultListAdministrators,
  ];

  const handleChange =
    (prop: keyof ICreateCommissions, option?: "limitNumber") =>
    (event: ChangeEvent<HTMLInputElement>) => {
      if (option === "limitNumber") {
        if (event.target.value.length <= 6) {
          setValuesInputsCommissions({
            ...valuesInputsCommissions,
            [prop]: event.target.value.replace(",", ""),
          });
        }
      } else {
        setValuesInputsCommissions({
          ...valuesInputsCommissions,
          [prop]: event.target.value,
        });
      }
    };

  return (
    <>
      <Grid container item spacing={2}>
        <Grid container item spacing={2}>
          <Grid item xs={12} sm={6}>
            <AppTextField
              fullWidth
              select
              label="Operadora *"
              value={valuesInputsCommissions.operatorCode}
              onChange={handleChange("operatorCode")}
            >
              {resultListOperators.map((option) => (
                <MenuItem key={option.code} value={option.code}>
                  {option.name}
                </MenuItem>
              ))}
            </AppTextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <AppTextField
              fullWidth
              select
              label="Modalidade(produto) *"
              value={valuesInputsCommissions.modalityCode}
              onChange={handleChange("modalityCode")}
            >
              {resultListModalities.map((option) => (
                <MenuItem key={option.code} value={option.code}>
                  {option.name}
                </MenuItem>
              ))}
            </AppTextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <AppTextField
              fullWidth
              select
              label="Administradora"
              value={valuesInputsCommissions.administratorCode}
              onChange={handleChange("administratorCode")}
            >
              {updatedResultListCompanies.map((option) => (
                <MenuItem key={option.code} value={option.code}>
                  {option.name}
                </MenuItem>
              ))}
            </AppTextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <AppTextField
              fullWidth
              select
              label="Empresas"
              value={valuesInputsCommissions.companyCode}
              onChange={handleChange("companyCode")}
            >
              {resultListCompanies.map((option) => (
                <MenuItem key={option.code} value={option.code}>
                  {option.name}
                </MenuItem>
              ))}
            </AppTextField>
          </Grid>
        </Grid>
      </Grid>

      <Divider sx={{ margin: "16px 0 " }} />

      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <AppTextField
            label="Vigência *"
            fullWidth
            type="date"
            value={valuesInputsCommissions?.effectiveDate}
            onChange={handleChange("effectiveDate")}
          />
        </Grid>

        <Grid item xs={6} sm={4}>
          <AppTextField
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            label="1Parc."
            fullWidth
            value={valuesInputsCommissions?.parc_1}
            onChange={handleChange("parc_1", "limitNumber")}
          />
        </Grid>

        <Grid item xs={6} sm={4}>
          <AppTextField
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            label="2Parc."
            fullWidth
            value={valuesInputsCommissions?.parc_2}
            onChange={handleChange("parc_2", "limitNumber")}
          />
        </Grid>

        <Grid item xs={6} sm={4}>
          <AppTextField
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            label="3Parc."
            fullWidth
            value={valuesInputsCommissions?.parc_3}
            onChange={handleChange("parc_3", "limitNumber")}
          />
        </Grid>

        <Grid item xs={6} sm={4}>
          <AppTextField
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            label="4Parc."
            fullWidth
            value={valuesInputsCommissions?.parc_4}
            onChange={handleChange("parc_4", "limitNumber")}
          />
        </Grid>

        <Grid item xs={6} sm={4}>
          <AppTextField
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            label="5Parc."
            fullWidth
            value={valuesInputsCommissions?.parc_5}
            onChange={handleChange("parc_5", "limitNumber")}
          />
        </Grid>

        <Grid item xs={6} sm={4}>
          <AppTextField
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            label="6Parc."
            fullWidth
            value={valuesInputsCommissions?.parc_6}
            onChange={handleChange("parc_6", "limitNumber")}
          />
        </Grid>
      </Grid>
    </>
  );
};
