import React, { useEffect } from "react";
import { useResetInputs } from "src/Contexts";
import { ModalCore } from "src/Pages/components";

import { FormModalidade } from "./FormModalidade";
import { UseFormModalidade } from "../Hook/UseFeaturesFormulario";

interface IModalModalidade {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalModalidade = ({
  openModal,
  setOpenModal,
}: IModalModalidade) => {
  const { resetInputs } = useResetInputs();

  const { handleSubmit, loading: loadingSubmit } = UseFormModalidade({
    setOpenModal,
  });

  useEffect(() => {
    if (!openModal) resetInputs();
  }, [openModal]);

  return (
    <ModalCore
      open={openModal}
      setOpen={setOpenModal}
      loadingSubmit={loadingSubmit}
      sxStyle={{ width: "500px" }}
      title="Modalidade"
      titleSubmit="Salvar"
      onChange={handleSubmit}
    >
      <FormModalidade />
    </ModalCore>
  );
};
