import { ChangeEvent } from "react";
import { Grid } from "@mui/material";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { ICreateContrato } from "src/Contexts/Lancamentos/Contrato/ContratosTypes";
import { AppTextField } from "src/Pages/components";
import {
  handleFormatPercent,
  apenasNumeros,
  FormatMoney,
} from "src/shared/Utils/FormatMoney";
import { cpfOurCnpj } from "src/shared/Utils/MaskCpfCnpj";

export const FormDados = () => {
  const { valuesInputsContrato, setValuesInputsContrato } =
    useContextContrato();

  const handleChange =
    (
      prop: keyof ICreateContrato,
      option?: "phone" | "money" | "percent" | "apenasNumero"
    ) =>
    (
      event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
    ) => {
      if (option === "money") {
        setValuesInputsContrato({
          ...valuesInputsContrato,
          [prop]: FormatMoney(event.target.value),
        });
      } else if (option === "phone") {
        setValuesInputsContrato({
          ...valuesInputsContrato,
          [prop]: cpfOurCnpj({ text: event.target.value, type: "phone" }),
        });
      } else if (option === "percent") {
        setValuesInputsContrato({
          ...valuesInputsContrato,
          [prop]: handleFormatPercent(event.target.value),
        });
      } else if (option === "apenasNumero") {
        setValuesInputsContrato({
          ...valuesInputsContrato,
          [prop]: apenasNumeros(event.target.value),
        });
      } else
        setValuesInputsContrato({
          ...valuesInputsContrato,
          [prop]: event.target.value,
        });
    };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} lg={2}>
        <AppTextField
          label="Data de emissão *"
          fullWidth
          type="date"
          value={valuesInputsContrato.issuanceDate}
          onChange={handleChange("issuanceDate")}
        />
      </Grid>
      <Grid item xs={6} lg={2}>
        <AppTextField
          label="Data de vigência*"
          type="date"
          fullWidth
          value={valuesInputsContrato.startDate}
          onChange={handleChange("startDate")}
        />
      </Grid>
      <Grid item xs={6} lg={2}>
        <AppTextField
          label="Vencimento 1parcela *"
          fullWidth
          type="date"
          value={valuesInputsContrato.firstInstallmentDueDate}
          onChange={handleChange("firstInstallmentDueDate")}
        />
      </Grid>
      <Grid item xs={6} lg={2}>
        <AppTextField
          label="Vencimento contrato *"
          tooltip="Data de aniversario/vencimento da proposta"
          fullWidth
          type="date"
          value={valuesInputsContrato.expirationDate}
          onChange={handleChange("expirationDate")}
        />
      </Grid>

      <Grid item xs={6} lg={2}>
        <AppTextField
          label="Mensalidade *"
          fullWidth
          value={valuesInputsContrato.monthlyPayment}
          onChange={handleChange("monthlyPayment", "money")}
        />
      </Grid>
      <Grid item xs={6} lg={2}>
        <AppTextField
          label="Vidas *"
          fullWidth
          value={valuesInputsContrato.lives}
          onChange={handleChange("lives", "apenasNumero")}
        />
      </Grid>

      {/* <Grid item xs={4} lg={2}>
        <ButtonCore title="Observação" onClick={() => setOpen(true)} />
      </Grid>
      <DialogCore
        PaperProps={{
          style: {
            padding: "1rem",
            minHeight: "300px",
            borderRadius: ".7rem",
            width: "80%",
            maxWidth: "800px",
          },
        }}
        open={open}
        setOpen={setOpen}
        title="Observações"
      >
        <RichText
          setValue={(e) => {
            setValuesInputsContrato({
              ...valuesInputsContrato,
              notes: e.toString(),
            });
          }}
          value={valuesInputsContrato.notes}
        />
      </DialogCore> */}
    </Grid>
  );
};
