import { Children, ReactNode, SetStateAction, Dispatch } from "react";
import {
  ListItemButton,
  ListItemText,
  Collapse,
  ListItemIcon,
} from "@mui/material";
import { IViews } from "src/Contexts/types/ProdutoresContext.Types";
import { IconExpandIsOpen } from "src/MainLayout/Drawers/aside/componentes/IconExpandIsOpen/IconExpandIsOpen";

import { AsideMenuLeft } from "../AsideMenuLeft";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";

export const handleVerifyAnyIsTrue = (valueButtons: IViews[]) => {
  return valueButtons.some((element) => element.isView === true);
};

interface IBaseMenuAside {
  setOpen: Dispatch<SetStateAction<boolean>>;
  Navigation: IViews[];
  icon: ReactNode;
  title: string;
  open: boolean;
}

export const BaseMenuAside = ({
  Navigation,
  setOpen,
  title,
  open,
  icon,
}: IBaseMenuAside) => {
  const { openAside } = useLayoutMainContext();
  return (
    <>
      {handleVerifyAnyIsTrue(Navigation) && (
        <ListItemButton
          onClick={() => setOpen(!open)}
          sx={{ borderRadius: "8px" }}
        >
          <ListItemIcon
            sx={{
              marginRight: "-20px",
              marginLeft: openAside ? "-8px" : "-12px",
            }}
          >
            {icon}
          </ListItemIcon>

          <ListItemText primary={title} />
          <IconExpandIsOpen IsOpen={open} />
        </ListItemButton>
      )}

      <Collapse unmountOnExit in={open} timeout="auto">
        {Children.toArray(
          Navigation.map((buttons) => {
            return (
              buttons.isView && (
                <AsideMenuLeft
                  title={buttons.route.title}
                  to={buttons.route.url}
                  disabled={buttons.isDevelop}
                  tooltip={buttons.isDevelop ? "Em desenvolvimento" : ""}
                />
              )
            );
          })
        )}
      </Collapse>
    </>
  );
};
