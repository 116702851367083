import { useState } from "react";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";
import { apenasNumeros } from "src/shared/Utils/FormatMoney";

interface IUseFormReceivedInstallment {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UseFormReceivedInstallment = ({
  setOpenModal,
}: IUseFormReceivedInstallment) => {
  const { setAttTable, id } = useConfigPageContext();

  const [loading, setLoading] = useState(false);

  const { valuesReceivedInstallment } = useContextContrato();
  const { handleGetAlert } = useLayoutMainContext();

  const handleEdit = () => {
    if (!valuesReceivedInstallment.valuePayment)
      return handleGetAlert({ message: `Digite um valor para receber` });
    if (!valuesReceivedInstallment.dateReceived)
      return handleGetAlert({ message: `Digite uma data de recebimento` });

    setLoading(true);
    const AllDatas = {
      id: valuesReceivedInstallment.id,
      valuePayment: Number(
        apenasNumeros(valuesReceivedInstallment.valuePayment)
      ),

      dateReceived: valuesReceivedInstallment.dateReceived,
      lives: valuesReceivedInstallment.lives,
      note: valuesReceivedInstallment.note,
    };

    return api
      .patch(`/installments/${id}`, AllDatas)
      .then((res) => {
        setOpenModal(false);
        setAttTable((item) => !item);
        handleGetAlert({ message: res.data.message });
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  return { handleSubmit: handleEdit, loading };
};
