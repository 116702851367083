import {
  createContext,
  useContext,
  useEffect,
  ReactNode,
  useState,
  FC,
} from "react";
import { GetDateUSSemValue } from "src/shared/Utils/FormatDateBR";

import {
  IListContratos,
  ICreateContrato,
  IContextContrato,
  IGetInstallments,
  ICancelarContrato,
  IUpdateInstallment,
  IValuesFilterBordero,
  IReceivedInstallment,
  IValuesFilterContrato,
  ICreateNewInstallment,
} from "./ContratosTypes";
import {
  valueDefaultInputReceivedInstallments,
  valuesDefaultFilterContrato,
  valueDefaultInputContratos,
  valuesDefaultFilterBordero,
} from "./valuesDefaultInputsContratos/ValueDefaultInputContratos";

const ContratosContext = createContext({} as IContextContrato);

export const ContratoContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [listContratos, setListContratos] = useState([] as IListContratos[]);
  const [getInstallments, setGetInstallments] = useState<IGetInstallments>();

  const [dataGetByIdContrato, setDataGetByIdContrato] =
    useState<IListContratos>();

  const [valuesInputCancelarContrato, setValuesInputCancelarContrato] =
    useState<ICancelarContrato>({
      date: `${GetDateUSSemValue()}`,
      description: "",
    });

  const [valuesInputUpdateInstallment, setValuesInputUpdateInstallment] =
    useState<IUpdateInstallment>();

  const [valuesInputNewInstallments, setValuesInputNewInstallments] =
    useState<ICreateNewInstallment>();

  const [valuesInputsContrato, setValuesInputsContrato] =
    useState<ICreateContrato>({ ...valueDefaultInputContratos });

  const [valuesFilterContrato, setValuesFilterContrato] =
    useState<IValuesFilterContrato>(valuesDefaultFilterContrato);

  const [valuesFilterBordero, setValuesFilterBordero] =
    useState<IValuesFilterBordero>(valuesDefaultFilterBordero);

  const [valuesReceivedInstallment, setValuesReceivedInstallment] =
    useState<IReceivedInstallment>(valueDefaultInputReceivedInstallments);

  useEffect(() => {
    setValuesFilterContrato((prevValue) => ({
      ...prevValue,
      endDateSignature: valuesFilterContrato.startDateSignature,
    }));
  }, [valuesFilterContrato.startDateSignature]);

  return (
    <ContratosContext.Provider
      value={{
        setValuesInputUpdateInstallment,
        valuesInputUpdateInstallment,
        setValuesInputCancelarContrato,
        setValuesInputNewInstallments,
        valuesInputNewInstallments,
        setValuesReceivedInstallment,
        valuesInputCancelarContrato,
        valuesReceivedInstallment,
        setValuesInputsContrato,
        setValuesFilterContrato,
        setDataGetByIdContrato,
        setValuesFilterBordero,
        valuesInputsContrato,
        valuesFilterContrato,
        valuesFilterBordero,
        dataGetByIdContrato,
        setGetInstallments,
        setListContratos,
        getInstallments,
        listContratos,
      }}
    >
      {children}
    </ContratosContext.Provider>
  );
};

export const useContextContrato = (): IContextContrato =>
  useContext(ContratosContext);
