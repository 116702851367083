import { useEffect } from "react";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { useGetCep } from "src/shared/Hooks/useGetCep";

export const UseGetCEPContratos = () => {
  const { handleGetCEP } = useGetCep();

  const { valuesInputsContrato, setValuesInputsContrato } =
    useContextContrato();

  useEffect(() => {
    if (valuesInputsContrato.postalCode?.length === 9) {
      const handleGet = async () => {
        const { erro, bairro, localidade, logradouro, uf } = await handleGetCEP(
          valuesInputsContrato.postalCode
        );

        if (erro) {
          // setValuesInputsContrato((prevEvent) => ({
          //   ...prevEvent,
          //   state: "",
          //   neighborhood: "",
          //   street: "",
          //   city: "",
          // }));
        } else {
          setValuesInputsContrato((prevEvent) => ({
            ...prevEvent,
            state: uf,
            neighborhood: bairro,
            street: logradouro,
            city: localidade,
          }));
        }
      };

      handleGet();
    }
  }, [valuesInputsContrato.postalCode]);
};
