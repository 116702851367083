import React, { useEffect } from "react";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { ModalCore } from "src/Pages/components";

import { FormEditarParcela } from "./FormEditarParcela";
import { UseFormFormNovaParcela } from "./UseFormFormEditarParcela";

interface IModalEditarParcela {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalEditarParcela = ({
  openModal,
  setOpenModal,
}: IModalEditarParcela) => {
  const { setValuesInputUpdateInstallment } = useContextContrato();

  useEffect(() => {
    if (!openModal) {
      setValuesInputUpdateInstallment(undefined);
    }
  }, [openModal]);

  const { handleEditarParcela, loading: loadingEditarParcela } =
    UseFormFormNovaParcela({ setOpenModal });

  return (
    <ModalCore
      loadingSubmit={loadingEditarParcela}
      open={openModal}
      setOpen={setOpenModal}
      sxStyle={{ width: "600px" }}
      title="Editar parcela"
      titleSubmit="Editar parcela"
      onChange={handleEditarParcela}
    >
      <FormEditarParcela />
    </ModalCore>
  );
};
