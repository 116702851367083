import { ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  ListItemText,
  ListItemIcon,
  ListItem,
  alpha,
  List,
  useTheme,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useResetInputs } from "src/Contexts/hooks/useResetInputs";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { LinkCore } from "src/Pages/components/LinkCore/LinkCore";

type Props = {
  to: string;
  title: string;
  disabled?: boolean;
  icon?: ReactNode;
  tooltip?: string;
};

export const AsideMenuLeft = ({
  title,
  to,
  icon,
  disabled,
  tooltip,
}: Props) => {
  const { currentPage, setCurrentPage } = useConfigPageContext();
  const { openAside, setOpenAside } = useLayoutMainContext();
  const { resetInputs } = useResetInputs();
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const { pathname } = useLocation();

  const isActive = pathname === to || false;

  const resetCurrentPage = () => {
    if (currentPage !== 1) setCurrentPage(1);
    resetInputs();
  };

  return (
    <LinkCore to={to} isDisabled={disabled}>
      <List
        disablePadding
        component="div"
        onClick={() => {
          if (matches) {
            setOpenAside(false);
          }
        }}
      >
        <Tooltip title={tooltip || ""}>
          <ListItem
            key={title}
            disabled={disabled}
            sx={{
              background: (theme) =>
                isActive ? alpha(theme.palette.primary.main, 0.9) : "",
              borderRadius: "12px",
              margin: "4px 0",
              transition: ".1s",
              color: (theme) =>
                isActive ? "#fff" : theme.palette.text.primary,
              "&:hover": {
                backgroundColor: (theme) =>
                  alpha(theme.palette.primary.main, 1),
              },
            }}
          >
            {icon ? (
              <ListItemIcon
                sx={{
                  marginLeft: openAside ? "-8px" : "-12px",
                  marginRight: "-20px",
                  "& > * ": {
                    color: (theme) =>
                      isActive ? "#fff" : theme.palette.text.primary,
                  },
                }}
              >
                {icon}
              </ListItemIcon>
            ) : (
              <ListItemIcon sx={{ marginRight: "-20px", marginLeft: "-8px" }} />
            )}

            <ListItemText
              primary={title}
              onClick={resetCurrentPage}
              sx={{
                color: (theme) =>
                  isActive ? "#fff" : theme.palette.text.primary,
              }}
            />
          </ListItem>
        </Tooltip>
      </List>
    </LinkCore>
  );
};
