import { formatString } from "@format-string/core";
import { Grid } from "@mui/material";
import { createRef, ChangeEvent, useEffect } from "react";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { IFormCompletionUser } from "src/Contexts/AuthContext/AuthContextTypes";

import { AppTextField } from "src/Pages/components/AppFormComponents/AppTextField";
import { cpfOurCnpj } from "src/shared/Utils/MaskCpfCnpj";

export const FormCompletionInfoUser = () => {
  const { setValuesInputsCompletionUser, valuesInputsCompletionUser } =
    useAuthContext();

  const refOrigem = createRef<HTMLInputElement>();
  const handleFocusedID = () => refOrigem.current?.focus();

  useEffect(() => {
    handleFocusedID();
  }, []);

  const handleChange =
    (prop: keyof IFormCompletionUser, option?: "phone" | "cpf") =>
    (event: ChangeEvent<HTMLInputElement>) => {
      if (option === "cpf") {
        setValuesInputsCompletionUser((eventPrev) => ({
          ...eventPrev,
          [prop]: cpfOurCnpj({ text: event.target.value, type: "cpf" }),
        }));
      } else if (option === "phone") {
        setValuesInputsCompletionUser((eventPrev) => ({
          ...eventPrev,
          [prop]: formatString({ value: event.target.value, type: "phone" }),
        }));
      } else {
        setValuesInputsCompletionUser((eventPrev) => ({
          ...eventPrev,
          [prop]: event.target.value,
        }));
      }
    };

  return (
    <Grid container item spacing={2}>
      <Grid item xs={12}>
        <AppTextField
          fullWidth
          inputRef={refOrigem}
          label="Nome completo *"
          value={valuesInputsCompletionUser?.name}
          onChange={handleChange("name")}
        />
      </Grid>

      <Grid item xs={6} sm={12}>
        <AppTextField
          label="CPF"
          fullWidth
          value={valuesInputsCompletionUser?.cpf}
          onChange={handleChange("cpf", "cpf")}
        />
      </Grid>

      <Grid item xs={6} sm={6}>
        <AppTextField
          label="Telefone 1"
          fullWidth
          value={valuesInputsCompletionUser?.phone1}
          onChange={handleChange("phone1", "phone")}
        />
      </Grid>

      <Grid item xs={6} sm={6}>
        <AppTextField
          label="Telefone 2"
          fullWidth
          value={valuesInputsCompletionUser?.phone2}
          onChange={handleChange("phone2", "phone")}
        />
      </Grid>
    </Grid>
  );
};
