import { ChangeEvent } from "react";
import { Grid } from "@mui/material";
import { useContextProfileContext } from "src/Contexts/ContextProfile/ContextProfile";
import { IUpdatePasswordProfile } from "src/Contexts/ContextProfile/ContextProfileTypes";
import { valuesDefaultUpdatePasswordProfile } from "src/Contexts/ContextProfile/ValuesDefaultContextProfile/ValuesDefaultContextProfile";
import { AppTextField, FormCore } from "src/Pages/components";

import { UseFormUpdateProfilePassword } from "./Hooks/UseFormUpdateProfilePassword";

export const FormProfilePassword = () => {
  const { setValuesUpdatePasswordProfile, valuesUpdatePasswordProfile } =
    useContextProfileContext();

  const { handleSubmit, loading: loadingUpdate } =
    UseFormUpdateProfilePassword();

  const handleChange =
    (prop: keyof IUpdatePasswordProfile) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setValuesUpdatePasswordProfile((prevValue) => ({
        ...prevValue,
        [prop]: event.target.value,
      }));
    };

  return (
    <FormCore
      handleCancel={() =>
        setValuesUpdatePasswordProfile(valuesDefaultUpdatePasswordProfile)
      }
      handleSubmit={handleSubmit}
      loadingSubmit={loadingUpdate}
      sxStyle={{ margin: 0 }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AppTextField
            label="Senha atual"
            sxStyle={{ width: "100%" }}
            value={valuesUpdatePasswordProfile?.password_currency}
            onChange={handleChange("password_currency")}
          />
        </Grid>

        <Grid item xs={12}>
          <AppTextField
            label="Nova senha"
            sxStyle={{ width: "100%" }}
            value={valuesUpdatePasswordProfile?.password_new}
            onChange={handleChange("password_new")}
          />
        </Grid>

        <Grid item xs={12}>
          <AppTextField
            label="Nova senha"
            sxStyle={{ width: "100%" }}
            value={valuesUpdatePasswordProfile?.password_new_repeat}
            onChange={handleChange("password_new_repeat")}
          />
        </Grid>
      </Grid>
    </FormCore>
  );
};
