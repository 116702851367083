import { GetDateUSSemValue } from "src/shared/Utils/FormatDateBR";

import {
  ICreateCommissions,
  IValuesFilterCommissions,
} from "../ComissoesTypes";

export const valuesDefaultFilterCommissions: IValuesFilterCommissions = {
  codeOperadora: "",
  name: "",
  isActive: "TRUE",
};

export const valuesDefaultInputsCommissions: ICreateCommissions = {
  effectiveDate: `${GetDateUSSemValue()}`,
  isActive: true,

  operatorCode: "",
  administratorCode: "",
  companyCode: "",
  modalityCode: "",

  parc_1: "",
  parc_2: "",
  parc_3: "",
  parc_4: "",
  parc_5: "",
  parc_6: "",
};
