import React, { createRef, useEffect } from "react";
import { useResetInputs } from "src/Contexts";
import { ModalCore } from "src/Pages/components";

import { UseFormContracts } from "./Hooks/UseFeaturesFormulario";
import { DividerCore } from "src/Pages/components/DividerCore/DividerCore";
import { FormContrato } from "./FormContrato";
import { FormTitular } from "./FormTitular";
import { FormContato } from "./FormContato";
import { FormDados } from "./FormDados";

interface IModalContracts {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalContracts = ({
  openModal,
  setOpenModal,
}: IModalContracts) => {
  const { resetInputs } = useResetInputs();

  const { handleSubmit, loading: loadingSubmit } = UseFormContracts({
    setOpenModal,
  });

  useEffect(() => {
    if (!openModal) resetInputs();
  }, [openModal]);

  const inputRef = createRef<HTMLInputElement>();

  const handleFocusedID = () => inputRef.current?.focus();

  useEffect(() => {
    handleFocusedID();
  }, []);

  return (
    <ModalCore
      open={openModal}
      setOpen={setOpenModal}
      loadingSubmit={loadingSubmit}
      sxStyle={{ width: { xl: "1000px", md: "600", xs: "90%" } }}
      title="Contratos"
      titleSubmit="Salvar"
      onChange={handleSubmit}
    >
      <DividerCore
        title="1 - Contrato"
        sxStyle={{ margin: "0 4px 20px 4px" }}
      />
      <FormContrato inputRef={inputRef} />

      <DividerCore title="2 - Titular" sxStyle={{ marginBottom: "20px" }} />
      <FormTitular />

      <DividerCore
        title="3 - Endereço/Contato"
        sxStyle={{ marginBottom: "20px" }}
      />
      <FormContato />

      <DividerCore title="4 - Dados" sxStyle={{ marginBottom: "20px" }} />
      <FormDados />
    </ModalCore>
  );
};
