import { useContextComissoes } from "src/Contexts/comissoes/ComissoesContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";
import { handleFormatDateIfIsNull } from "src/shared/Utils/handleFormatDateIfIsNull";

type CommissionInstallment = {
  code: number;
  commissionCode: number;
  parcelNumber: number;
  valueCommission: number;
  createdAt: string; // ISO date string
  updatedAt: string; // ISO date string
  idTenant: string;
};

export type MyObjectType = {
  data: {
    code: number;
    isActive: boolean;
    createdAt: string; // ISO date string
    updatedAt: string; // ISO date string
    effectiveDate: string; // ISO date string
    operatorCode: number;
    modalityCode: number;
    companyCode: number;
    administratorCode: number;
    idTenant: string;
    commissionInstallments: CommissionInstallment[];
  };
};

export const UseGetByIdCommissions = () => {
  const { setValuesInputsCommissions } = useContextComissoes();
  const { handleGetAlert } = useLayoutMainContext();

  const handleGetById = async (id: string) => {
    await api
      .get<MyObjectType>(`/commissions/${id}`)
      .then(({ data: { data } }) => {
        setValuesInputsCommissions({
          effectiveDate: handleFormatDateIfIsNull(data.effectiveDate),

          isActive: data.isActive,

          administratorCode: String(data.administratorCode),
          companyCode: String(data.companyCode),
          modalityCode: String(data.modalityCode),
          operatorCode: String(data.operatorCode),

          parc_1:
            data.commissionInstallments
              .find((item) => item.parcelNumber === 1)
              ?.valueCommission.toString() || "",
          parc_2:
            data.commissionInstallments
              .find((item) => item.parcelNumber === 2)
              ?.valueCommission.toString() || "",
          parc_3:
            data.commissionInstallments
              .find((item) => item.parcelNumber === 3)
              ?.valueCommission.toString() || "",
          parc_4:
            data.commissionInstallments
              .find((item) => item?.parcelNumber === 4)
              ?.valueCommission.toString() || "",
          parc_5:
            data.commissionInstallments
              .find((item) => item?.parcelNumber === 5)
              ?.valueCommission.toString() || "",

          parc_6:
            data.commissionInstallments
              .find((item) => item?.parcelNumber === 6)
              ?.valueCommission.toString() || "",
        });
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      );
  };

  return { handleGetById };
};
