export const handleGetAllPlansDetails = () => {
  return [
    { title: "Propostas/Contratos ilimitados", isDefault: true },
    { title: "Perfeito para quem quer flexibilidade", isDefault: true },
    { title: "Relatórios (Dashboard/Analise)", isDefault: true },
    { title: "Cancele a qualquer momento, sem multa", isDefault: true },
    { title: "Suporte e treinamento", isDefault: true },
    { title: "Software 100% online", isDefault: true },
  ];
};
