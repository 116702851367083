import { useState } from "react";

import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { Tab, Tabs } from "@mui/material";
import { Faq } from "./Components/PageFaq/Faq/Faq";
import { PageSubscriptions } from "./Components/PageSubscriptions/PageSubscriptions";

export const PagePlansConfig = () => {
  const [value, setValue] = useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <Tabs value={value} onChange={handleChange}>
        <Tab label="Assinatura" value="1" />
        <Tab label="Duvidas | FAQ" value="2" />
      </Tabs>

      <TabPanel value="1" sx={{ padding: 0 }}>
        <PageSubscriptions />
      </TabPanel>

      <TabPanel value="2" sx={{ padding: 0 }}>
        <Faq />
      </TabPanel>
    </TabContext>
  );
};
