import React, { useEffect } from "react";
import { useResetInputs } from "src/Contexts";
import { ModalCore } from "src/Pages/components";

import { FormReceivedInstallment } from "./FormReceberParcela/FormReceivedInstallment";
import { UseFormReceivedInstallment } from "./FormReceberParcela/hook/UseFormReceivedInstallment";

interface IModalReceberParcela {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalReceivedInstallment = ({
  openModal,
  setOpenModal,
}: IModalReceberParcela) => {
  const { resetInputs } = useResetInputs();

  const { handleSubmit, loading: loadingSubmit } = UseFormReceivedInstallment({
    setOpenModal,
  });

  useEffect(() => {
    if (!openModal) resetInputs();
  }, [openModal]);

  return (
    <ModalCore
      loadingSubmit={loadingSubmit}
      open={openModal}
      setOpen={setOpenModal}
      sxStyle={{ width: "500px" }}
      title="Receber parcela"
      titleSubmit="Salvar"
      onChange={handleSubmit}
    >
      <FormReceivedInstallment />
    </ModalCore>
  );
};
