interface IListFaq {
  title: string;
  description: string;
  defaultExpanded: boolean;
}

export const ListFaq: IListFaq[] = [
  {
    title: "Posso alterar meu plano a qualquer momento ?",
    description: `Sim, solicite um novo plano.`,
    defaultExpanded: true,
  },
  {
    title: "Existe um período de teste gratuito ?",
    description: `Sim, 7 dias de avaliação com tudo liberado.`,
    defaultExpanded: true,
  },
  {
    title: "Como faço para cancelar meu plano ?",
    description: `Você pode solicitar o cancelamento a qualquer momento.`,
    defaultExpanded: false,
  },
  {
    title: "O Correctors oferece suporte técnico ?",
    description: `Sim, nossa equipe esta disponível para manutenção básica em
    horário comercial e para problemas que limita o uso da plataforma
    tem o suporte 24h.`,
    defaultExpanded: false,
  },
  {
    title: "O sistema é atualizado regularmente ?",
    description: `Sim, o Correctors tem atualização semanal, toda atualização você
    será notificado e você não paga nada por isso.`,
    defaultExpanded: true,
  },
  {
    title: "Tenho direito a treinamento ?",
    description: `Sim, solicite o treinamento, normalmente em 1 semana você fica
    apto 100%.`,
    defaultExpanded: false,
  },

  {
    title: "Posso acessar pelo celular ?",
    description: `Sim, o Correctors é 100% online, você pode acessar de qualquer
    dispositivo com internet.`,
    defaultExpanded: false,
  },
  {
    title: "Preciso instalar algumas coisa no meu computador ?",
    description: `Não, o Correctors é 100% online, você precisa de um navegador
    (Google Chrome, Microsoft Edge, Apple Safari, Opera, Mozilla Firefox).`,
    defaultExpanded: false,
  },
  {
    title: "Meus dados estarão seguros ?",
    description: `Sim. Usamos os servidores mais modernos do mercado, os mesmos de
    grandes marcas como Netflix e iFood..`,
    defaultExpanded: false,
  },
  {
    title: "E se eu tiver mais algumas perguntas ?",
    description: `Nós ficaremos felizes em respondê-las. Entre em contato pelo whats
    (11) 96663-7697.`,
    defaultExpanded: true,
  },
];
