import {
  Box,
  alpha,
  useTheme,
  Typography,
  Breadcrumbs,
  useMediaQuery,
} from "@mui/material";
import { LinkCore } from "../LinkCore/LinkCore";
import { Children } from "react";
import { ToggleTheme } from "src/MainLayout/Header/components/ToggleTheme/ToggleTheme";
import { AvatarLogout } from "src/MainLayout/Header/components/LogouImg/AvatarLogout";
import { ToggleOpenMenu } from "src/MainLayout/Header/components/ToggleOpenMenu/ToggleOpenMenu";

export interface IBreadcrumbsCore {
  data: {
    to: string;
    title: string;
    subsTitle?: string;
  }[];
}

export const BreadcrumbsCore = (data: IBreadcrumbsCore) => {
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "16px",
      }}
    >
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{
          fontSize: { xs: "1rem", sm: "1.2rem", lg: "1.3rem" },
          fontWeight: "500",
          letterSpacing: ".5px",
        }}
      >
        {Children.toArray(
          data.data.map((item) => {
            return (
              <>
                <LinkCore
                  to={item.to}
                  color={alpha(theme.palette.text.primary, 0.6)}
                >
                  {matches && <ToggleOpenMenu />}
                  {item.title}
                </LinkCore>
                <Typography>{item.subsTitle}</Typography>
              </>
            );
          })
        )}
      </Breadcrumbs>
      <Box sx={{ display: "flex" }}>
        <ToggleTheme />
        <AvatarLogout />
      </Box>
    </Box>
  );
};
