import { formatRemove } from "@format-string/core";
import { useState } from "react";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";
import { apenasNumeros } from "src/shared/Utils/FormatMoney";

interface IUseFormContracts {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UseFormContracts = ({ setOpenModal }: IUseFormContracts) => {
  const { setAttTable, attTable, id } = useConfigPageContext();
  const { valuesInputsContrato } = useContextContrato();
  const { handleGetAlert } = useLayoutMainContext();

  const [loading, setLoading] = useState(false);

  const AllDatas: IContract = {
    ...valuesInputsContrato,

    companyCode: Number(valuesInputsContrato.companyCode),
    operatorCode: Number(valuesInputsContrato.operatorCode),
    modalityCode: Number(valuesInputsContrato.modalityCode),

    administratorCode: valuesInputsContrato.administratorCode
      ? Number(valuesInputsContrato.administratorCode)
      : null,

    monthlyPayment: Number(apenasNumeros(valuesInputsContrato.monthlyPayment)),

    cpf: formatRemove(valuesInputsContrato.cpf),
    rg: formatRemove(valuesInputsContrato.rg),

    lives: Number(valuesInputsContrato.lives),
    commissionGrade: "A",

    contractContact: {
      email: valuesInputsContrato.email,
      phone1: formatRemove(valuesInputsContrato.phone1),
      phone2: formatRemove(valuesInputsContrato.phone2),
    },
    contractAddress: {
      neighborhood: valuesInputsContrato.neighborhood,
      complement: valuesInputsContrato.complement,
      postalCode: valuesInputsContrato.postalCode,
      street: valuesInputsContrato.street,
      number: valuesInputsContrato.number,
      state: valuesInputsContrato.state,
      city: valuesInputsContrato.city,
    },
  };

  const handleCreate = async () => {
    if (!AllDatas.proposalCode)
      return handleGetAlert({ message: `Digite uma proposta !` });

    setLoading(true);

    return api
      .post("/contracts", AllDatas)
      .then((res) => {
        handleGetAlert({ message: res.data.message });
        setAttTable(!attTable);
        setOpenModal(false);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  const handleEdit = () => {
    if (!AllDatas.proposalCode)
      return handleGetAlert({ message: `Digite uma proposta !` });

    setLoading(true);

    return api
      .put(`/contracts/${id}`, AllDatas)
      .then((res) => {
        handleGetAlert({ message: res.data.message });
        setAttTable(!attTable);
        setOpenModal(false);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  const handleSubmit = () => {
    if (id) {
      handleEdit();
    } else {
      handleCreate();
    }
  };

  return { handleSubmit, loading };
};

type IContract = {
  subscriptionDate: string; // ISO date string
  contractType: string;
  proposalCode: string;

  companyCode: number;
  operatorCode: number;
  modalityCode: number;
  administratorCode: number | null;

  commissionGrade: string;

  cpf: string;
  holder: string | null;
  rg: string;
  maritalStatus: string;
  birthDate: string; // ISO date string
  gender: string;
  motherName: string;
  guardianName: string;
  guardianCpf: string;
  guardianBirthDate: string | null; // ISO date string or null

  cnpj: string;
  companyName: string;

  issuanceDate: string; // ISO date string
  startDate: string; // ISO date string
  firstInstallmentDueDate: string; // ISO date string
  monthlyPayment: number;

  lives: number;
  notes: string;

  contractAddress: {
    postalCode: string;
    street: string;
    number: string;
    state: string;
    city: string;
    neighborhood: string;
    complement: string;
  };

  contractContact: {
    email: string;
    phone1: string;
    phone2: string;
  };
};
