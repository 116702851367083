import { FC } from "react";
import { Paper, SxProps, Theme } from "@mui/material";
import { CardProps } from "@mui/material/Card/Card";

type ICardCore = CardProps & {
  sxStyle?: SxProps<Theme>;
  elevation?: number;
};

export const CardCore: FC<ICardCore> = ({
  sxStyle,
  children,
  elevation,
  ...props
}) => {
  return (
    <Paper
      elevation={elevation || 2}
      sx={{
        margin: "1rem",
        padding: { xs: ".7rem", sm: "16px" },
        ...sxStyle,
      }}
      {...props}
    >
      {children}
    </Paper>
  );
};
