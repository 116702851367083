import { Box, Grid, Typography } from "@mui/material";
import { PublicRoutes } from "src/Routes/PublicRoutes";

import backgroundImgDesktop from "./components/media/loginBG.png";

export const BasePagePublic = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        backgroundSize: "cover",
        padding: { xs: "1.6rem", sm: "3rem" },
        backgroundImage: {
          md: `url(${backgroundImgDesktop})`,
        },
      }}
    >
      <Grid
        container
        sx={{
          height: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid xs={12} lg={6} item>
          <PublicRoutes />
        </Grid>

        <Grid
          xs={0}
          lg={6}
          item
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "left",
            padding: "2rem",
          }}
        >
          <Typography sx={{ color: "white", fontSize: "32px", mb: "2rem" }}>
            Seja bem-vindo
          </Typography>

          <Typography sx={{ color: "white", fontSize: "16px", mb: ".5rem" }}>
            Simplifique sua gestão, Faça o controle das suas propostas, parcelas
            <br />e faça campanha para seus leads, tudo dentro do correctors
          </Typography>
          <Typography sx={{ color: "white", fontSize: "16px" }}>
            Cadastre-se e ganhe 7 dias grátis
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
