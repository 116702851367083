import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { useContextReportsProduction } from "src/Contexts/ReportProduction/ReportsProductionContextContext";
import { api } from "src/shared/setup/API/api";

export const UseReportConfirmationProducer = () => {
  const { userPerfil } = useAuthContext();
  const { handleGetAlert } = useLayoutMainContext();
  const { setLoadingGravar } = useConfigPageContext();

  const {
    setListReportConfirmationProducer,
    valueAutoCompAdministradoras,
    inputsConfirmationProducer,
    valueAutoCompModalidades,
    valueAutoCompOperadoras,
  } = useContextReportsProduction();

  const objData = {
    start_date_payment: inputsConfirmationProducer.start_date_payment,
    end_date_payment: inputsConfirmationProducer.end_date_payment,

    start_date_due: inputsConfirmationProducer.start_date_due,
    end_date_due: inputsConfirmationProducer.end_date_due,

    start_date_signature: inputsConfirmationProducer.start_date_signature,
    end_date_signature: inputsConfirmationProducer.end_date_signature,

    operadoras: valueAutoCompOperadoras.map((item) => item.id),
    modalidades: valueAutoCompModalidades.map((item) => item.id),
    administradoras: valueAutoCompAdministradoras.map((item) => item.id),

    type_report: "PRODUCER",

    code_produtor: String(userPerfil?.idTenant),
  };

  const handleGetReportProductionContract = async () => {
    setLoadingGravar(true);
    setListReportConfirmationProducer(undefined);

    const AllDatas = { ...objData };

    return api
      .post("/report/confirmationProducer", AllDatas)
      .then((res) => {
        setListReportConfirmationProducer(res.data);
      })
      .catch((error) => {
        handleGetAlert({ message: error.response.data.message });
      })
      .finally(() => setLoadingGravar(false));
  };

  const handleSubmit = () => handleGetReportProductionContract();

  return { handleSubmit };
};
