import { IDataTh, ThCoreIndex } from "src/Pages/components";

export const TheadPayments = () => {
  const dataTh: IDataTh[] = [
    {
      title: "Data de vencimento",
      width: "150px",
      align: "left",
    },
    {
      title: "Mensalidade",
      align: "left",
      width: "150px",
    },
    {
      title: "Método de pagamento",
      align: "left",
      width: "200px",
    },
    {
      title: "Data de pagamento",
      align: "left",
      width: "150px",
    },
    {
      title: "Status do pagamento",
      align: "left",
      width: "150px",
    },
    {
      title: "Link de pagamento",
      align: "left",
      width: "150px",
    },
    {
      title: "Recibo de pagamento",
      align: "left",
      width: "150px",
    },
  ];

  return <ThCoreIndex key={1} dataTh={dataTh} />;
};
