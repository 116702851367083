import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { CardContract } from "src/Pages/components/CardContract/CardContract";
import { TitleWithValueCore } from "src/Pages/components/TitleWithValueCore/TitleWithValueCore";
import { FormatDateBR } from "src/shared/Utils/FormatDateBR";
import { FormatMoney } from "src/shared/Utils/FormatMoney";
import { handleFormatDateIfIsNull } from "src/shared/Utils/handleFormatDateIfIsNull";

export const CardDadosPlano = () => {
  const { dataGetByIdContrato } = useContextContrato();

  if (!handleFormatDateIfIsNull) return null;

  return (
    <>
      <CardContract title="Plano">
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="Operadora"
          value={dataGetByIdContrato?.operator.name}
        />
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="Modalidade"
          value={dataGetByIdContrato?.modality.name}
        />

        {dataGetByIdContrato?.administrator?.name && (
          <TitleWithValueCore
            sxStyleTitle={{ fontWeight: "500" }}
            title="Administradora"
            value={dataGetByIdContrato?.administrator.name}
          />
        )}

        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          sxStyleValue={{ color: (theme) => theme.custom.color.money }}
          title="Mensalidade"
          value={FormatMoney(dataGetByIdContrato?.monthlyPayment)}
        />

        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="Vidas"
          value="1"
        />

        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="Data de vencimento 1pa *"
          value={FormatDateBR(
            handleFormatDateIfIsNull(
              dataGetByIdContrato?.firstInstallmentDueDate
            )
          )}
        />
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="Data de assinatura"
          value={FormatDateBR(
            handleFormatDateIfIsNull(dataGetByIdContrato?.subscriptionDate)
          )}
        />
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="Data de emissão"
          value={FormatDateBR(
            handleFormatDateIfIsNull(dataGetByIdContrato?.issuanceDate)
          )}
        />
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="Data de vigência"
          value={FormatDateBR(
            handleFormatDateIfIsNull(dataGetByIdContrato?.startDate)
          )}
        />
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="Data de vencimento proposta"
          value={FormatDateBR(
            handleFormatDateIfIsNull(dataGetByIdContrato?.expirationDate)
          )}
        />
      </CardContract>
    </>
  );
};
