import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { useContextReportsProduction } from "src/Contexts/ReportProduction/ReportsProductionContextContext";
import { api } from "src/shared/setup/API/api";

export const UseReportProductionContract = () => {
  const { userPerfil } = useAuthContext();
  const { handleGetAlert } = useLayoutMainContext();
  const { setLoadingGravar } = useConfigPageContext();

  const {
    setListReportProductionContract,
    valuesInputsProductionContract,
    valueAutoCompAdministradoras,
    valueAutoCompModalidades,
    valueAutoCompOperadoras,
  } = useContextReportsProduction();

  const objData = {
    start_date: valuesInputsProductionContract.start_date,
    end_date: valuesInputsProductionContract.end_date,
    type_date: valuesInputsProductionContract.type_date,
    is_group: valuesInputsProductionContract.is_group === "TRUE",

    operadoras: valueAutoCompOperadoras.map((item) => item.id),
    modalidades: valueAutoCompModalidades.map((item) => item.id),
    administradoras: valueAutoCompAdministradoras
      .map((item) => item.id)
      .filter((id) => id !== ""),

    type_report: "PRODUCER",

    code_produtor: String(userPerfil?.idTenant),
  };

  const handleGetReportProductionContract = async () => {
    if (!valuesInputsProductionContract.start_date)
      return handleGetAlert({ message: `Escolha uma data inicial !` });

    if (!valuesInputsProductionContract.end_date)
      return handleGetAlert({ message: `Escolha uma data final !` });

    setLoadingGravar(true);
    setListReportProductionContract(undefined);

    const AllDatas = { ...objData };

    return api
      .post("/report/productionContract", AllDatas)
      .then((res) => {
        setListReportProductionContract(res.data);
      })
      .catch((error) => {
        handleGetAlert({ message: error.response.data.message });
      })
      .finally(() => setLoadingGravar(false));
  };

  const handleSubmit = () => handleGetReportProductionContract();

  return { handleSubmit };
};
