import { Box, Typography, useTheme } from "@mui/material";
import { LinkCore } from "src/Pages/components/LinkCore/LinkCore";

export const HeaderLogin = () => {
  const theme = useTheme();
  return (
    <Box sx={{ mb: "1rem" }}>
      <Typography
        sx={{
          color: (theme) => theme.palette.primary.main,
          fontWeight: "500",
          fontSize: { xs: "18px", sm: "22px" },
          mb: "1rem",
        }}
      >
        Faça seu login
      </Typography>

      <Box
        sx={{
          display: "flex",
          "& > *": {
            fontSize: { xs: "12px", sm: "16px" },
          },
        }}
      >
        <Typography sx={{ mr: "8px" }}>Não tem uma conta?</Typography>

        <Typography sx={{ fontWeight: "500" }}>
          <LinkCore color={`${theme.palette.primary.main}`} to="/register">
            Experimente grátis por 7 dias !
          </LinkCore>
        </Typography>
      </Box>
    </Box>
  );
};
