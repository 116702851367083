import { createContext, useContext, useState, FC, ReactNode } from "react";

import {
  IListEmpresas,
  IListOperadora,
  ICreateEmpresas,
  IListModalidade,
  ICreateOperadora,
  IListCommissions,
  ICreateModalidade,
  IContextComissoes,
  ICreateCommissions,
  IListAdministradora,
  ICreateAdministradora,
  IValuesFilterCommissions,
} from "./ComissoesTypes";
import {
  valuesDefaultInputsCommissions,
  valuesDefaultFilterCommissions,
} from "./ValuesDefaultInputsComissoes/ValuesDefaultInputsComissoes";

const ComissoesContext = createContext({} as IContextComissoes);

export const ComissoesContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [listModalidade, setListModalidade] = useState([] as IListModalidade[]);
  const [listOperadora, setListOperadora] = useState([] as IListOperadora[]);

  const [listAdministradora, setListAdministradora] = useState(
    [] as IListAdministradora[]
  );
  const [listEmpresas, setListEmpresas] = useState([] as IListEmpresas[]);
  const [listCommissions, setListCommissions] = useState(
    [] as IListCommissions[]
  );

  const [valuesInputsAdministradora, setValuesInputsAdministradora] =
    useState<ICreateAdministradora>({ name: "", isActive: true });

  const [valuesInputsOperadora, setValuesInputsOperadora] =
    useState<ICreateOperadora>({ name: "", isActive: true });

  const [valuesInputsModalidade, setValuesInputsModalidade] =
    useState<ICreateModalidade>({ name: "", isActive: true });

  const [valuesInputsEmpresas, setValuesInputsEmpresas] =
    useState<ICreateEmpresas>({ name: "", isActive: true });

  const [valuesInputsCommissions, setValuesInputsCommissions] =
    useState<ICreateCommissions>(valuesDefaultInputsCommissions);

  const [valuesFilterCommissions, setValuesFilterCommissions] =
    useState<IValuesFilterCommissions>(valuesDefaultFilterCommissions);

  return (
    <ComissoesContext.Provider
      value={{
        listEmpresas,
        listOperadora,
        listModalidade,
        setListEmpresas,
        listCommissions,
        setListOperadora,
        setListModalidade,
        setListCommissions,
        listAdministradora,
        valuesInputsEmpresas,
        setListAdministradora,
        valuesInputsOperadora,
        valuesInputsModalidade,
        setValuesInputsEmpresas,
        valuesInputsCommissions,
        valuesFilterCommissions,
        setValuesInputsOperadora,
        setValuesInputsModalidade,
        valuesInputsAdministradora,
        setValuesInputsCommissions,
        setValuesFilterCommissions,
        setValuesInputsAdministradora,
      }}
    >
      {children}
    </ComissoesContext.Provider>
  );
};

export const useContextComissoes = (): IContextComissoes =>
  useContext(ComissoesContext);
