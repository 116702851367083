import React, { useEffect } from "react";
import { useResetInputs } from "src/Contexts";
import { ModalCore } from "src/Pages/components";

import { FormEmpresas } from "./FormularioEmpresas";
import { UseFormEmpresas } from "./Hook/UseFeaturesFormulario";

interface IModalEmpresas {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalEmpresas = ({ openModal, setOpenModal }: IModalEmpresas) => {
  const { resetInputs } = useResetInputs();

  const { handleSubmit, loading: loadingSubmit } = UseFormEmpresas({
    setOpenModal,
  });

  useEffect(() => {
    if (!openModal) resetInputs();
  }, [openModal]);

  return (
    <ModalCore
      loadingSubmit={loadingSubmit}
      open={openModal}
      setOpen={setOpenModal}
      sxStyle={{ width: "500px" }}
      title="Empresa"
      titleSubmit="Salvar"
      onChange={handleSubmit}
    >
      <FormEmpresas />
    </ModalCore>
  );
};
