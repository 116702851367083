import { useState } from "react";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

export const UseGetContractById = () => {
  const { handleGetAlert } = useLayoutMainContext();
  const { setDataGetByIdContrato, dataGetByIdContrato } = useContextContrato();

  const [isLoading, setIsLoading] = useState(false);

  const handleGet = (id: string) => {
    setIsLoading(true);

    if (!dataGetByIdContrato?.proposalCode) {
      api
        .get(`/contracts/${id}`)
        .then((result) => {
          setDataGetByIdContrato(result.data);
        })
        .catch((error) =>
          handleGetAlert({ message: error.response.data.message })
        );
    }
  };

  return { isLoading, handleGet };
};
