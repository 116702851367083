import { Children, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import { useContextComissoes } from "src/Contexts/comissoes/ComissoesContext";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { ModalDeleteCore, TableCore, TdCore } from "src/Pages/components";
import {
  DeleteForeverIcon,
  FolderCopyIcon,
  FolderOffIcon,
  CheckIcon,
  CloseIcon,
  EditIcon,
} from "src/Pages/components/Icons/Icons";

import { UseDelete } from "src/Pages/components/ModalDeleteCore/Hooks/UseDelete";
import { TableRowCore } from "src/Pages/components/table/@Core/TableRowCore/TableRowCore";
import { ActionPopoverTable } from "src/Pages/components/table/ActionPopover/ActionPopover";

import { useHttpTableModalidade } from "./Hooks/useHttpTableModalidade";
import { THeadModalidade } from "./THead";
import { ModalModalidade } from "../Form/indexForm";
import { ModalChangeIsActiveModalidade } from "../ChangeIsActiveModalidade/ModalChangeIsActiveModalidade";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";

export const TableModalidade = () => {
  const { listModalidade, setValuesInputsModalidade } = useContextComissoes();
  const { attTable, setId } = useConfigPageContext();
  const { handleGet } = useHttpTableModalidade();

  const [openModalModalidade, setOpenModalModalidade] = useState(false);
  const [openModalActiveModalidade, setOpenModalActiveModalidade] =
    useState(false);
  const [valueActiveModalidade, setValueActiveModalidade] =
    useState<boolean>(true);

  const handleGetIdToEdit = (id: string, Modalidade: string) => {
    setId(id);
    setOpenModalModalidade(true);
    setValuesInputsModalidade({ name: Modalidade, isActive: true });
  };

  useEffect(() => {
    handleGet();
  }, [attTable]);

  const {
    handleDelete,
    open: openModalDelete,
    loading: loadingDelete,
    setId: setIdModalDelete,
    setOpen: setOpenModalDelete,
  } = UseDelete({ url: "/modalities" });

  return (
    <TableCore
      isNotPagination
      title="Modalidades"
      Modals={
        <>
          <ModalModalidade
            openModal={openModalModalidade}
            setOpenModal={setOpenModalModalidade}
          />
          <ModalDeleteCore
            loading={loadingDelete}
            modalOpen={openModalDelete}
            no="Não, Cancelar"
            titlePrimary="Você deseja excluir da lista ?"
            yes="Sim, Deletar !"
            onClickFalse={() => setOpenModalDelete(false)}
            onClickTrue={() => handleDelete()}
          />
          <ModalChangeIsActiveModalidade
            isActiveOperadora={valueActiveModalidade}
            isOpenModal={openModalActiveModalidade}
            setOpenModal={setOpenModalActiveModalidade}
          />
        </>
      }
      THead={<THeadModalidade />}
      qtdList={listModalidade.length}
      heightTable="420px"
      cardAdd={{
        title: "Modalidades",
        buttons: [
          <ButtonCore
            startIcon={<AddIcon />}
            size="small"
            title="Criar"
            onClick={() => setOpenModalModalidade(true)}
          />,
        ],
      }}
    >
      {Children.toArray(
        listModalidade.map((item) => {
          return (
            <TableRowCore id={String(item.code)}>
              <TdCore
                values={
                  <ActionPopoverTable
                    optionsList={[
                      {
                        icon: <EditIcon fontSize="small" />,
                        title: "Editar",
                        background: "warning",
                        onClick: () =>
                          handleGetIdToEdit(String(item.code), item.name),
                      },
                      {
                        icon: item.isActive ? (
                          <FolderOffIcon fontSize="small" />
                        ) : (
                          <FolderCopyIcon fontSize="small" />
                        ),
                        isDisabled: true,
                        background: "warning",
                        title: item.isActive ? "Desativar" : "Ativar",
                        onClick: () => {
                          setId(item.name);
                          setOpenModalActiveModalidade(true);
                          setValueActiveModalidade(!item.isActive);
                        },
                      },
                      {
                        icon: <DeleteForeverIcon fontSize="small" />,
                        title: "Excluir",
                        background: "error",
                        onClick: () => {
                          setIdModalDelete(item.code);
                          setOpenModalDelete(true);
                        },
                      },
                    ]}
                  />
                }
              />
              <TdCore values={item.name} />

              <TdCore
                textAlign="center"
                values={
                  item.isActive ? (
                    <CheckIcon color="success" />
                  ) : (
                    <CloseIcon color="error" />
                  )
                }
              />
            </TableRowCore>
          );
        })
      )}
    </TableCore>
  );
};
