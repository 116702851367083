import { useState } from "react";
import moment from "moment";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";
import { FormatMoney } from "src/shared/Utils/FormatMoney";
import { handleFormatDateIfIsNull } from "src/shared/Utils/handleFormatDateIfIsNull";

interface IUseFormGetParcelasByIdContratos {
  id: string;
}

export const UseGetParcelasByIdContratos = ({
  id,
}: IUseFormGetParcelasByIdContratos) => {
  const { handleGetAlert } = useLayoutMainContext();
  const { setValuesInputNewInstallments } = useContextContrato();

  const [loading, setLoading] = useState(false);

  const handleGetNovaParcelaById = async () => {
    setValuesInputNewInstallments(undefined);

    setLoading(true);

    return api
      .get(`/installments/last-installment/${id}`)
      .then(({ data: { data } }) => {
        setValuesInputNewInstallments({
          lives: data.lives,
          installmentNumber: `${Number(data.installmentNumber) + 1}`,
          contractValue: FormatMoney(data.contractValue),
          commissionPercentage: data.commissionPercentage,
          dueDate: moment(handleFormatDateIfIsNull(data.dueDate))
            .add(1, "months")
            .format("YYYY-MM-DD"),
        });
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    handleGetNovaParcelaById,
    loading,
  };
};
