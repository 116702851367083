import { PhoneInTalk } from "@mui/icons-material";
import { Box, CardMedia, Grid, Typography, alpha } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";
import { CardCore } from "src/Pages/components/CardCore/CardCore";
import { CardBodyCore } from "src/Pages/components/CardCore/Components/CardBodyCore";
import { getLinkWhatApp } from "src/Pages/components/CardPlans/CardPlans";
import { ExpandMoreIcon, QuizIcon } from "src/Pages/components/Icons/Icons";
import { toAbsoluteUrl } from "src/shared/setup/toAbsoluteUrl";

import { FormFaqHeader } from "./Form/FormFaqHeader";
import { ListFaq } from "./ListFaq";

export const Faq = () => {
  const { userPerfil } = useAuthContext();

  return (
    <CardCore sxStyle={{ margin: "16px 0" }}>
      <CardBodyCore icon={<QuizIcon />} title="Duvidas frequentes ?">
        <Grid container sx={{ display: "flex", paddingTop: "1rem" }}>
          <Grid item md={6} sx={{ marginBottom: "1rem" }} xs={12}>
            {ListFaq.map((item, index) => {
              return (
                <Accordion
                  key={item.title}
                  defaultExpanded={item.defaultExpanded}
                  sx={{
                    borderLeft: (theme) =>
                      `3px solid ${
                        Math.floor(index % 2) === 0
                          ? alpha(theme.palette.primary.main as string, 0.7)
                          : alpha(theme.palette.primary.main as string, 0.4)
                      }`,
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{ fontSize: { xs: ".8rem", sm: ".9rem", lg: "1rem" } }}
                  >
                    <Typography>{item.title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={{ fontSize: "14px" }}>
                      {item.description}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Grid>

          <Grid item md={6} xs={0}>
            <Box sx={{ margin: { xs: "", md: "0 12px 12px 32px" } }}>
              <CardMedia
                alt="Imagem de boas vindas"
                component="img"
                image={toAbsoluteUrl("/media/faq-img-662b762f.png")}
                sx={{ width: "100%", marginBottom: "1rem" }}
              />

              <FormFaqHeader />

              <Box
                component="a"
                href={getLinkWhatApp(
                  "5511966637697",
                  `Olá, me chamo ${userPerfil?.name}, tenho uma duvida: .`
                )}
                sx={{ textDecoration: "none" }}
                target="_blank"
              >
                <ButtonCore
                  fullWidth
                  startIcon={<PhoneInTalk />}
                  title="Enviar duvida"
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardBodyCore>
    </CardCore>
  );
};
