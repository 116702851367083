import { Box, MenuItem } from "@mui/material";
import { ChangeEvent, useEffect } from "react";
import { useContextDashboardContext } from "src/Contexts/Dashboard/ContextDashboardBroker";
import { IMetricsFilterDashboard } from "src/Contexts/Dashboard/ContextDashboardBrokerTypes";
import { AppTextField, FormCore } from "src/Pages/components";
import { UseMetricsDashboard } from "./Hooks/UseMetricsDashboard";
import { UseFormMonthByMonthProfitChart } from "../components/MonthByMonthProfitChart/Hooks/UseFormMonthByMonthProfitChart";
import { UseFormInstallmentsReceivedPrimary } from "../components/InstallmentsReceivedPrimary/Hooks/UseFormInstallmentsReceivedPrimary";
import { UseFormInstallmentsReceived } from "../components/InstallmentsReceived/Hooks/UseFormInstallmentsReceived";

export const FormMetrics = () => {
  const { setFilterMetricsFilterDashboard, filterMetricsFilterDashboard } =
    useContextDashboardContext();

  const { handleSubmit, loading } = UseMetricsDashboard();
  const { handleSubmit: handleSubmitProfit } = UseFormMonthByMonthProfitChart();
  const { handleSubmit: handleSubmitReceivedPrimary } =
    UseFormInstallmentsReceivedPrimary();
  const { handleSubmit: handleSubmitReceived } = UseFormInstallmentsReceived();

  const handles = () => {
    handleSubmit();
    handleSubmitProfit();
    handleSubmitReceived();
    handleSubmitReceivedPrimary();
  };

  useEffect(() => {
    handleSubmit();
    handleSubmitProfit();
    handleSubmitReceived();
    handleSubmitReceivedPrimary();
  }, []);

  const handleChange =
    (prop: keyof IMetricsFilterDashboard) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setFilterMetricsFilterDashboard((prevValue) => ({
        ...prevValue,
        [prop]: event.target.value,
      }));
    };

  return (
    <FormCore
      titleSubmit="Filtrar"
      isNotCancel
      handleSubmit={handles}
      loadingSubmit={loading}
      marginTopButtons="0px"
      sxStyle={{
        margin: "0",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
      }}
    >
      <Box>
        <AppTextField
          label="Inicio *"
          sxStyle={{ width: "150px", margin: { xs: "4px", sm: "4px 6px" } }}
          type="date"
          value={filterMetricsFilterDashboard.startDate}
          onChange={handleChange("startDate")}
        />

        <AppTextField
          label="Final *"
          sxStyle={{ width: "150px", margin: { xs: "4px", sm: "4px 6px" } }}
          type="date"
          value={filterMetricsFilterDashboard?.endDate}
          onChange={handleChange("endDate")}
        />
        <AppTextField
          select
          label="Intervalo *"
          value={filterMetricsFilterDashboard?.interval}
          onChange={handleChange("interval")}
          sxStyle={{ width: "150px", margin: { xs: "4px", sm: "4px 6px" } }}
        >
          <MenuItem value="DAY">Dia</MenuItem>
          <MenuItem value="WEEK">Semana</MenuItem>
          <MenuItem value="MONTH">Mês</MenuItem>
        </AppTextField>
      </Box>
    </FormCore>
  );
};
