import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { PageAdministradoras } from "./Adminstradoras";
import { PageModalidades } from "./Modalidades";
import { PageEmpresa } from "./Empresas";
import { PageOperadoras } from "./Operadoras";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";

export const PageCadastro = () => {
  const { openAside } = useLayoutMainContext();

  const theme = useTheme();
  const isBreakPoint = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={openAside && isBreakPoint ? 6 : 3} lg={3}>
        <PageOperadoras />
      </Grid>
      <Grid item xs={12} sm={6} md={openAside && isBreakPoint ? 6 : 3} lg={3}>
        <PageModalidades />
      </Grid>
      <Grid item xs={12} sm={6} md={openAside && isBreakPoint ? 6 : 3} lg={3}>
        <PageEmpresa />
      </Grid>
      <Grid item xs={12} sm={6} md={openAside && isBreakPoint ? 6 : 3} lg={3}>
        <PageAdministradoras />
      </Grid>
    </Grid>
  );
};
