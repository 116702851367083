import { CardHeaderCore } from "src/Pages/components/CardCore/Components/CardHeaderCore";
import { InstallmentsReceived } from "./components/InstallmentsReceived";
import { InstallmentsReceivedPrimary } from "./components/InstallmentsReceivedPrimary";
import { MonthByMonthProfitChart } from "./components/MonthByMonthProfitChart";
import { Cards } from "./Metrics";
import { HeaderIsPlanTest } from "../../components/HeaderIsPlanTest/HeaderIsPlanTest";
import { FormatDateBR } from "src/shared/Utils/FormatDateBR";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useContextDashboardContext } from "src/Contexts/Dashboard/ContextDashboardBroker";
import { FormMetrics } from "./Form/FormMetrics";

export const PageDashboardBroker = () => {
  const { filterMetricsFilterDashboard } = useContextDashboardContext();

  return (
    <>
      <HeaderIsPlanTest />

      <CardHeaderCore
        buttonAdd={[<FormMetrics key={1} />]}
        icon={<FilterListIcon />}
        sxStyle={{ margin: "0 0 16px 0", padding: "12px", boxShadow: "" }}
        title={`Filtro: ${FormatDateBR(
          filterMetricsFilterDashboard.startDate
        )} até ${FormatDateBR(filterMetricsFilterDashboard.endDate)}`}
      />

      <Cards />

      <MonthByMonthProfitChart />
      {/* 
      <Grid container spacing={2} sx={{ marginBottom: "24px" }}>
        <Grid item xs={12} md={8}>
          <BarChartIncomingExpense />
        </Grid>

        <Grid item xs={12} md={4}>
          <PieGraphicIncomingExpense />
        </Grid>
      </Grid> */}

      <InstallmentsReceivedPrimary />

      <InstallmentsReceived />
    </>
  );
};
