import { Children, useEffect } from "react";
import { formatString } from "@format-string/core";
import { Box, LinearProgress } from "@mui/material";
import { PDFViewer, Document, View } from "@react-pdf/renderer";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextReportsProduction } from "src/Contexts/ReportProduction/ReportsProductionContextContext";
import { PDFCabeçalho } from "src/Pages/components/PDF/Cabeçalho";
import { ItemMapToInfoSearch } from "src/Pages/components/PDF/ItemMapToInfoSearch";
import { LineItem } from "src/Pages/components/PDF/LineItem/LineItem";
import { NumberPages } from "src/Pages/components/PDF/NumberPages";
import { PageCorePDF } from "src/Pages/components/PDF/PageCorePDF";
import { TableBase } from "src/Pages/components/PDF/TableBase";
import { PDFTableRow } from "src/Pages/components/PDF/TableRow";
import { TableRowContainer } from "src/Pages/components/PDF/TableRowContainer";
import { handleFormatDateWithHours } from "src/shared/Utils/FormatDate/FormatDateToCodeProcucer";
import { FormatDateBR, GetDateUSSemValue } from "src/shared/Utils/FormatDateBR";
import { FormatMoney } from "src/shared/Utils/FormatMoney";
import { HandleGetHoursCurrency } from "src/shared/Utils/HandleGetHoursCurrency";
import { handleIfLimitCharacter } from "src/shared/Utils/LimitText";

import { HeadReportProductionContract } from "./HeadReportProductionContractPDF";

export const ReportProductionContractPDF = () => {
  const { userPerfil } = useAuthContext();
  const { loadingGravar } = useConfigPageContext();
  const { listReportProductionContract, setListReportProductionContract } =
    useContextReportsProduction();

  const data = listReportProductionContract?.data;
  const meta = listReportProductionContract?.meta;
  const resume = listReportProductionContract?.resume;

  useEffect(() => setListReportProductionContract(undefined), []);

  const tiposDate = {
    VIGENCIA: "Vigência",
    ASSINATURA: "Assinatura",
    EMISSAO: "Emissão",
  };

  return (
    <>
      {userPerfil && data?.length && resume && meta && (
        <PDFViewer style={{ height: "84vh" }} width="100%">
          <Document
            creator="Correctors"
            title={
              meta?.typeReport === "ALL"
                ? "Relatório de produção por contrato"
                : `${data[0].produtor.name}-${handleFormatDateWithHours()}`
            }
          >
            <PageCorePDF
              key={resume.totalVidas}
              orientation="landscape"
              padding="16px 8px 30px 8px"
            >
              {meta?.dataPayments.startDate && meta?.dataPayments.endDate && (
                <PDFCabeçalho
                  dateCurrency={FormatDateBR(GetDateUSSemValue())}
                  empresa=""
                  endDate={FormatDateBR(meta.dataPayments.endDate)}
                  hoursCurrency={HandleGetHoursCurrency()}
                  nomePdf="Relatório de produção por contrato"
                  startDate={FormatDateBR(meta.dataPayments.startDate)}
                />
              )}

              <View
                key={resume.totalVidas}
                fixed
                style={{
                  borderBottom: "1px solid black",
                  marginBottom: "14px",
                }}
              >
                <ItemMapToInfoSearch
                  itens={
                    meta?.typeReport === "UNIQUE" ? [data[0].produtor.name] : []
                  }
                  title="Produtor"
                />
                <ItemMapToInfoSearch
                  itens={[tiposDate[meta.dataPayments.typeDate]]}
                  title="Tipo de data"
                />
                <ItemMapToInfoSearch itens={meta?.filiais} title="Filiais" />
                <ItemMapToInfoSearch
                  itens={meta?.operadoras}
                  title="Operadoras"
                />
                <ItemMapToInfoSearch
                  itens={meta?.modalidades}
                  title="Modalidades"
                />
                <ItemMapToInfoSearch
                  itens={meta?.administradoras}
                  title="Administradoras"
                />
              </View>

              <TableBase key={resume.totalVidas} margin="0">
                <HeadReportProductionContract />

                {Children.toArray(
                  data.map(({ contratos, produtor, resume }) => {
                    return (
                      <>
                        {listReportProductionContract.meta.is_group && (
                          <LineItem id={produtor.id} name={produtor.name} />
                        )}

                        {Children.toArray(
                          contratos.map((itemContrato) => {
                            return (
                              <TableRowContainer key={produtor.id}>
                                <PDFTableRow
                                  paddingLeft="8px"
                                  title={handleIfLimitCharacter({
                                    LIMIT: 28,
                                    value: itemContrato.operadora.name,
                                  })}
                                  width="22%"
                                />
                                <PDFTableRow
                                  paddingLeft="8px"
                                  title={handleIfLimitCharacter({
                                    LIMIT: 30,
                                    value: itemContrato.codProposta,
                                  })}
                                  width="22%"
                                />
                                <PDFTableRow
                                  title={handleIfLimitCharacter({
                                    LIMIT: 40,
                                    value: itemContrato.titular,
                                  })}
                                  width="30%"
                                />
                                <PDFTableRow
                                  title={
                                    itemContrato.tipoContrato === "PJ"
                                      ? (formatString({
                                          value: itemContrato.cpfCnpj,
                                          type: "cnpj",
                                        }) as string)
                                      : (formatString({
                                          value: itemContrato.cpfCnpj,
                                          type: "cpf",
                                        }) as string)
                                  }
                                  width="10%"
                                />
                                <PDFTableRow
                                  textAlign="right"
                                  title={FormatDateBR(
                                    itemContrato.dataAssinatura
                                  )}
                                  width="10%"
                                />
                                <PDFTableRow
                                  textAlign="right"
                                  title={FormatMoney(
                                    itemContrato.valorMensalidade
                                  )}
                                  width="10%"
                                />
                                <PDFTableRow
                                  textAlign="center"
                                  title={itemContrato.vidas}
                                  width="8%"
                                />
                              </TableRowContainer>
                            );
                          })
                        )}

                        {listReportProductionContract.meta.is_group && (
                          <TableRowContainer key={produtor.id}>
                            <PDFTableRow
                              fontSize={10}
                              paddingLeft="8px"
                              title={`Total - ${resume.totalQtdPropostas}`}
                              width="90%"
                            />

                            <PDFTableRow
                              fontSize={10}
                              textAlign="right"
                              title={FormatMoney(
                                resume.totalMensalidades?.toFixed(2)
                              )}
                              width="8%"
                            />
                            <PDFTableRow
                              fontSize={10}
                              textAlign="center"
                              title={resume.totalQtdVidas}
                              width="7%"
                            />
                          </TableRowContainer>
                        )}
                      </>
                    );
                  })
                )}

                <TableRowContainer
                  key={resume.totalMensalidade}
                  backgroundColor="#eeeeee"
                >
                  <PDFTableRow
                    fontSize={11}
                    paddingLeft="8px"
                    title={`Total - ${resume.totalPropostas}`}
                    width="85%"
                  />

                  <PDFTableRow
                    fontSize={11}
                    textAlign="right"
                    title={FormatMoney(resume.totalMensalidade.toFixed(2))}
                    width="8%"
                  />
                  <PDFTableRow
                    fontSize={11}
                    textAlign="center"
                    title={resume.totalVidas}
                    width="7%"
                  />
                </TableRowContainer>
              </TableBase>

              <NumberPages />
            </PageCorePDF>
          </Document>
        </PDFViewer>
      )}

      {loadingGravar && (
        <Box style={{ width: "100%" }}>
          <LinearProgress color="inherit" />
        </Box>
      )}
    </>
  );
};
