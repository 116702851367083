import { FC, ReactNode } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { useStyles } from "src/Pages/components/ModalFilterCore/Index";

import { drawerWidth } from "../DrawerDesktop";

export const CardMain: FC<{ children: ReactNode }> = ({ children }) => {
  const { openAside } = useLayoutMainContext();

  const theme = useTheme();
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box
      className={classes.customScrollbar}
      sx={{
        maxHeight: matches ? `100vh` : "",
        transition: "max-height 0.5s ease-in-out",
        overflow: "auto",

        padding: { xs: "8px", sm: "24px", xl: "32px" },

        [theme.breakpoints.up("md")]: {
          transition: ".3s ease-in-out",
          marginLeft: openAside ? `${drawerWidth}px` : "56px",
        },
      }}
    >
      {children}
    </Box>
  );
};
