import { createTheme } from "@mui/material";
import { customVariables, paletteTheme } from "./Components";

export const LightTheme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "--TextField-brandBorderColor": "#4e4e4e",
          "--TextField-brandBorderHoverColor": "#4e4e4e",
          "--TextField-brandBorderFocusedColor": "#4e4e4e",
          "& label.Mui-focused": {
            color: "var(--TextField-brandBorderFocusedColor)",
          },
          "& .MuiInput-outlined:before": {
            borderBottomColor: "#4e4e4e", // Cor da linha padrão
          },
          "& .MuiInput-outlined:hover:not(.Mui-disabled):before": {
            borderBottomColor: "#4e4e4e", // Cor da linha no hover
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#4e4e4e", // Cor da borda para variant="outlined"
            },
            "&:hover fieldset": {
              borderColor: "#4e4e4e", // Cor da borda no hover
            },
            "&.Mui-focused fieldset": {
              borderColor: "#4e4e4e", // Cor da borda ao focar
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
        },
      },
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: paletteTheme.color.primary,
      contrastText: "#fff",
    },
    action: {
      disabled: "#6b78898d",
    },
  },
  custom: customVariables.light,
});
