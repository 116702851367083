import React, { useEffect, useState } from "react";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { ModalCore } from "src/Pages/components";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";
import { AddCardIcon } from "src/Pages/components/Icons/Icons";

import { FormNewInstallments } from "./FormNewInstallments/FormNewInstallments";
import { UseFormFormNewInstallments } from "./FormNewInstallments/Hooks/UseFormNewInstallments";
import { UseGetParcelasByIdContratos } from "./FormNewInstallments/Hooks/UseGetParcelasByIdContratos";
interface IModalNovaParcela {
  id: string;
}

export const ModalNovaParcela = ({ id }: IModalNovaParcela) => {
  const { setValuesInputNewInstallments } = useContextContrato();

  const { handleGetNovaParcelaById } = UseGetParcelasByIdContratos({ id });

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setValuesInputNewInstallments(undefined);

    if (openModal) {
      handleGetNovaParcelaById();
    }
  }, [openModal]);

  const { handleFormNewInstallments, loading: loadingModal } =
    UseFormFormNewInstallments({ cod_contrato: id, setOpenModal });

  return (
    <>
      {id && (
        <ButtonCore
          startIcon={<AddCardIcon />}
          title="Nova parcela"
          onClick={() => setOpenModal(true)}
        />
      )}

      <ModalCore
        loadingSubmit={loadingModal}
        open={openModal}
        setOpen={setOpenModal}
        sxStyle={{ width: "600px" }}
        title="Criar nova parcela"
        titleSubmit="Criar parcela"
        onChange={handleFormNewInstallments}
      >
        <FormNewInstallments />
      </ModalCore>
    </>
  );
};
