import { FC, ReactNode } from "react";
import {
  Box,
  alpha,
  Theme,
  SxProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import { FileCopyIcon } from "../Icons/Icons";

type ICardContract = TypographyProps & {
  title?: string;
  sxStyle?: SxProps<Theme>;
  children: ReactNode;
};

export const CardContract: FC<ICardContract> = ({
  title,
  sxStyle,
  children,
  ...props
}) => {
  return (
    <Box>
      {title && ( // Fazer isso um componente
        <Box
          sx={{
            borderRadius: "8px",
            display: "flex",
            alignItems: "center",
            overflow: "auto",
            mb: 2,
            border: (theme) =>
              `2px solid ${alpha(theme.palette.primary.main, 0.5)}`,
          }}
        >
          <Box
            sx={{
              padding: "8px",
              background: (theme) => alpha(theme.palette.primary.main, 0.3),
            }}
          >
            <FileCopyIcon
              fontSize="small"
              color="primary"
              sx={{ mb: "-3px" }}
            />
          </Box>
          <Typography sx={{ ml: 1, fontWeight: "500" }} {...props}>
            {title}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          padding: "16px 24px",
          borderRadius: "8px",
          flexWrap: "wrap",
          display: "flex",
          mb: "16px",
          border: (theme) =>
            `2px solid ${alpha(theme.palette.primary.main, 0.5)}`,
          "& > *": { margin: "12px 24px 12px 0" },
          ...sxStyle,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
