import { useEffect, useState } from "react";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

export interface IGetAdministratorsAll {
  code: number;
  name: string;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  idTenant: string;
}

export const HttpGetAdministradorasAll = () => {
  const { handleGetAlert } = useLayoutMainContext();

  const [data, setData] = useState<IGetAdministratorsAll[]>([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleGet = () => {
      setLoading(true);

      api
        .get(`/administrators/all`)
        .then(({ data }) => {
          setData(data.data);
        })
        .catch((error) =>
          handleGetAlert({ message: error.response.data.message })
        )
        .finally(() => setLoading(false));
    };

    handleGet();
  }, []);

  return { loading, data };
};
