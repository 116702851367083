import { Box, Tooltip, Typography } from "@mui/material";
import { toAbsoluteUrl } from "src/shared/setup/toAbsoluteUrl";
import { UrlConfig } from "src/shared/Utils/paths";
import { LinkCore } from "src/Pages/components/LinkCore/LinkCore";

export const LogoHeader = () => {
  return (
    <Tooltip title={UrlConfig.dashboard.title}>
      <LinkCore to={UrlConfig.dashboard.url}>
        <Box sx={{ display: { xs: "none", md: "flex", alignItems: "center" } }}>
          <img
            id="desktop"
            alt="logo da empresa "
            src={toAbsoluteUrl("/media/logos/CORRECTORS - curta.svg")}
            style={{ height: "80px" }}
          />
          <Typography
            sx={{
              marginLeft: "-18px",
              marginTop: "16px",
              fontSize: "14px",
              letterSpacing: 1,
            }}
          >
            Correctors
          </Typography>
        </Box>
        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <img
            id="mobile"
            alt="logo da empresa "
            src={toAbsoluteUrl("/media/logos/CORRECTORS - curta.svg")}
            style={{ height: "60px" }}
          />
        </Box>
      </LinkCore>
    </Tooltip>
  );
};
