import { IconButton } from "@mui/material";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { MenuIcon } from "src/Pages/components/Icons/Icons";

export const ToggleOpenMenu = () => {
  const { setOpenAside } = useLayoutMainContext();

  return (
    <IconButton sx={{}} onClick={() => setOpenAside((prevValue) => !prevValue)}>
      <MenuIcon />
    </IconButton>
  );
};
