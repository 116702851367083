import { IDataTh, ThCoreIndex } from "src/Pages/components";

export const THeadContracts = () => {
  const dataTh: IDataTh[] = [
    {
      title: "Ações",
      width: "40px",
      align: "left",
    },
    {
      title: "Propostas",
      align: "center",
      width: "135px",
      minWidth: "135px",
    },
    {
      title: "Data Assinatura",
      align: "left",
      width: "130px",
      minWidth: "130px",
      tooltip: "Data da assinatura",
    },
    {
      title: "Operadoras",
      align: "left",
      minWidth: "100px",
      width: "100px",
    },

    {
      title: "Mensalidade",
      align: "left",
      width: "50px",
    },
    {
      title: "Titular/Razão",
      align: "left",
      width: "200px",
      minWidth: "150px",
    },
    {
      title: "Telefones",
      align: "left",
      width: "120px",
      minWidth: "120px",
    },
    {
      title: "Administradoras",
      align: "left",
      minWidth: "130px",
      width: "130px",
    },
    {
      title: "Status",
      align: "left",
      width: "80px",
    },
  ];

  return <ThCoreIndex dataTh={dataTh} />;
};
