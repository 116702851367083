import { useState } from "react";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { handleRedirect } from "src/Pages/TelasSistemas/Plans/Components/PageSubscriptions/CardPlan/CardPlan";
import { api } from "src/shared/setup/API/api";
import { apenasNumeros } from "src/shared/Utils/FormatMoney";

interface IFormCompletionInfoUser {
  idPlan: string;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UseFormCompletionInfoUser = ({
  idPlan,
  setOpenModal,
}: IFormCompletionInfoUser) => {
  const { valuesInputsCompletionUser } = useAuthContext();
  const { handleGetAlert } = useLayoutMainContext();

  const [loading, setLoading] = useState(false);

  const handleCreate = async () => {
    setLoading(true);

    return api
      .patch("/users/completedAccount", {
        name: valuesInputsCompletionUser.name,
        cpf: apenasNumeros(valuesInputsCompletionUser.cpf),
        phone1: apenasNumeros(valuesInputsCompletionUser.phone1),
        phone2: apenasNumeros(valuesInputsCompletionUser.phone2),
      })
      .then((res) => {
        handleGetAlert({ message: res.data.message });
        setOpenModal(false);

        api
          .post("/checkout", { id: idPlan })
          .then((data) =>
            handleRedirect(data.data.dataSubscriptions.data[0].invoiceUrl)
          )
          .catch((error) =>
            handleGetAlert({ message: error.response.data.message })
          );
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  return { handleSubmit: handleCreate, loading };
};
