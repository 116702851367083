import { Children, useEffect, useState } from "react";

import { useContextComissoes } from "src/Contexts/comissoes/ComissoesContext";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { ModalDeleteCore, TableCore, TdCore } from "src/Pages/components";
import {
  DeleteForeverIcon,
  FolderCopyIcon,
  FolderOffIcon,
  CheckIcon,
  CloseIcon,
  EditIcon,
  AddIcon,
} from "src/Pages/components/Icons/Icons";

import { UseDelete } from "src/Pages/components/ModalDeleteCore/Hooks/UseDelete";
import { TableRowCore } from "src/Pages/components/table/@Core/TableRowCore/TableRowCore";
import { ActionPopoverTable } from "src/Pages/components/table/ActionPopover/ActionPopover";

import { useHttpTableOperadora } from "./Hooks/useHttpTableOperadora";
import { THeadOperadora } from "./THead";
import { ModalOperadora } from "../Form/indexForm";
import { ModalChangeIsActiveOperadora } from "../ChangeIsActiveProducer/ModalChangeIsActiveOperadora";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";

export const TableOperadora = () => {
  const { listOperadora, setValuesInputsOperadora } = useContextComissoes();
  const { attTable, setId } = useConfigPageContext();
  const { handleGet } = useHttpTableOperadora();

  const [openModalOperadora, setOpenModalOperadora] = useState(false);
  const [openModalActiveOperadora, setOpenModalActiveOperadora] =
    useState(false);
  const [valueActiveOperadora, setValueActiveOperadora] =
    useState<boolean>(true);

  const handleGetIdToEdit = (id: string, operadora: string) => {
    setId(id);
    setOpenModalOperadora(true);
    setValuesInputsOperadora({ name: operadora, isActive: true });
  };

  useEffect(() => {
    handleGet();
  }, [attTable]);

  const {
    handleDelete,
    open: openModalDelete,
    loading: loadingDelete,
    setId: setIdModalDelete,
    setOpen: setOpenModalDelete,
  } = UseDelete({ url: "/operators" });

  return (
    <TableCore
      heightTable="420px"
      title="Operadoras"
      Modals={
        <>
          <ModalOperadora
            openModal={openModalOperadora}
            setOpenModal={setOpenModalOperadora}
          />
          <ModalDeleteCore
            loading={loadingDelete}
            modalOpen={openModalDelete}
            no="Não, Cancelar"
            titlePrimary="Você deseja excluir da lista ?"
            yes="Sim, Deletar !"
            onClickFalse={() => setOpenModalDelete(false)}
            onClickTrue={() => handleDelete()}
          />
          <ModalChangeIsActiveOperadora
            isActiveOperadora={valueActiveOperadora}
            modalIsActiveOperadora={openModalActiveOperadora}
            setModalIsActiveOperadora={setOpenModalActiveOperadora}
          />
        </>
      }
      THead={<THeadOperadora />}
      qtdList={listOperadora.length}
      isNotPagination
      cardAdd={{
        title: "Operadoras",
        buttons: [
          <ButtonCore
            startIcon={<AddIcon />}
            title="Criar"
            size="small"
            onClick={() => setOpenModalOperadora(true)}
          />,
        ],
      }}
    >
      {Children.toArray(
        listOperadora.map((item) => {
          return (
            <TableRowCore id={String(item.code)}>
              <TdCore
                values={
                  <ActionPopoverTable
                    optionsList={[
                      {
                        icon: <EditIcon fontSize="small" />,
                        title: "Editar",
                        background: "warning",
                        onClick: () =>
                          handleGetIdToEdit(String(item.code), item.name),
                      },
                      {
                        icon: item.isActive ? (
                          <FolderOffIcon fontSize="small" />
                        ) : (
                          <FolderCopyIcon fontSize="small" />
                        ),
                        isDisabled: true,
                        background: "warning",
                        title: item.isActive ? "Desativar" : "Ativar",
                        onClick: () => {
                          setId(item.name);
                          setOpenModalActiveOperadora(true);
                          setValueActiveOperadora(!item.isActive);
                        },
                      },
                      {
                        icon: <DeleteForeverIcon fontSize="small" />,
                        title: "Excluir",
                        background: "error",
                        onClick: () => {
                          setIdModalDelete(item.code);
                          setOpenModalDelete(true);
                        },
                      },
                    ]}
                  />
                }
              />
              <TdCore values={item.name} />

              <TdCore
                textAlign="center"
                values={
                  item.isActive ? (
                    <CheckIcon color="success" />
                  ) : (
                    <CloseIcon color="error" />
                  )
                }
              />
            </TableRowCore>
          );
        })
      )}
    </TableCore>
  );
};
