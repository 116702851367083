/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createRef, useEffect, ChangeEvent } from "react";
import { Grid } from "@mui/material";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { ICreateNewInstallment } from "src/Contexts/Lancamentos/Contrato/ContratosTypes";
import { AppTextField } from "src/Pages/components";
import { FormatMoney, handleFormatPercent } from "src/shared/Utils/FormatMoney";

export const FormNewInstallments = () => {
  const { valuesInputNewInstallments, setValuesInputNewInstallments } =
    useContextContrato();

  const refOrigem = createRef<HTMLInputElement>();
  const handleFocusedID = () => refOrigem.current?.focus();

  useEffect(() => {
    handleFocusedID();
  }, []);

  const handleChange =
    (
      prop: keyof ICreateNewInstallment,
      option?: "limitNumber" | "money" | "percent"
    ) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      if (option === "money") {
        setValuesInputNewInstallments((prevValue) => ({
          ...prevValue!,
          [prop]: FormatMoney(event.target.value),
        }));
      } else if (option === "limitNumber") {
        if (event.target.value.length <= 5) {
          setValuesInputNewInstallments((prevValue) => ({
            ...prevValue!,
            [prop]: event.target.value,
          }));
        }
      } else if (option === "percent") {
        if (event.target.value.length <= 7) {
          setValuesInputNewInstallments((prevValue) => ({
            ...prevValue!,
            [prop]: handleFormatPercent(event.target.value),
          }));
        }
      } else {
        setValuesInputNewInstallments((prevValue) => ({
          ...prevValue!,
          [prop]: event.target.value,
        }));
      }
    };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <AppTextField
          fullWidth
          label="Vencimento *"
          type="date"
          value={valuesInputNewInstallments?.dueDate}
          onChange={handleChange("dueDate")}
        />
      </Grid>
      <Grid item xs={3}>
        <AppTextField
          fullWidth
          label="Parcela *"
          inputRef={refOrigem}
          value={valuesInputNewInstallments?.installmentNumber}
          type="number"
          onChange={handleChange("installmentNumber")}
        />
      </Grid>

      <Grid item xs={3}>
        <AppTextField
          fullWidth
          label="Vidas *"
          type="number"
          value={valuesInputNewInstallments?.lives}
          onChange={handleChange("lives")}
        />
      </Grid>

      <Grid item xs={6}>
        <AppTextField
          fullWidth
          label="Mensalidade *"
          value={valuesInputNewInstallments?.contractValue}
          onChange={handleChange("contractValue", "money")}
        />
      </Grid>

      <Grid item xs={6}>
        <AppTextField
          fullWidth
          label="Comissão % *"
          value={valuesInputNewInstallments?.commissionPercentage}
          onChange={handleChange("commissionPercentage", "percent")}
        />
      </Grid>
    </Grid>
  );
};
