import { CardHeaderContracts } from "./Page/CardHeader/CardHeaderContracts";
import { TableContracts } from "./Page/Table/TableContracts";

export const PageContracts = () => {
  return (
    <>
      <CardHeaderContracts />
      <TableContracts />
    </>
  );
};
