import { useContextComissoes } from "src/Contexts/comissoes/ComissoesContext";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

export const useHttpTableEmpresas = () => {
  const { setQtdRegisters, setLoadingTable } = useConfigPageContext();
  const { setListEmpresas } = useContextComissoes();
  const { handleGetAlert } = useLayoutMainContext();

  const handleGet = async () => {
    setLoadingTable(true);

    api
      .get("/companies/all")
      .then((res) => {
        setListEmpresas(res.data.data);
        setQtdRegisters(res.data.meta.total);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoadingTable(false));
  };

  return { handleGet };
};
