import { Children, useEffect } from "react";
import { Box, LinearProgress } from "@mui/material";
import { PDFViewer, Document, View } from "@react-pdf/renderer";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextReportsProduction } from "src/Contexts/ReportProduction/ReportsProductionContextContext";
import { PDFCabeçalho } from "src/Pages/components/PDF/Cabeçalho";
import { ItemMapToInfoSearch } from "src/Pages/components/PDF/ItemMapToInfoSearch";
import { LineItem } from "src/Pages/components/PDF/LineItem/LineItem";
import { PageCorePDF } from "src/Pages/components/PDF/PageCorePDF";
import { TableBase } from "src/Pages/components/PDF/TableBase";
import { PDFTableRow } from "src/Pages/components/PDF/TableRow";
import { TableRowContainer } from "src/Pages/components/PDF/TableRowContainer";
import { handleFormatDateWithHours } from "src/shared/Utils/FormatDate/FormatDateToCodeProcucer";
import { FormatDateBR, GetDateUSSemValue } from "src/shared/Utils/FormatDateBR";
import { FormatMoney } from "src/shared/Utils/FormatMoney";
import { HandleGetHoursCurrency } from "src/shared/Utils/HandleGetHoursCurrency";

import { HeadReportConfirmationProducerPDF } from "./HeadReportConfirmationProducerPDF";

export const ReportConfirmationProducerPDF = () => {
  const { userPerfil } = useAuthContext();
  const { loadingGravar } = useConfigPageContext();
  const { listReportConfirmationProducer, setListReportConfirmationProducer } =
    useContextReportsProduction();

  const data = listReportConfirmationProducer?.data;
  const meta = listReportConfirmationProducer?.meta;
  const resume = listReportConfirmationProducer?.resume;

  useEffect(() => setListReportConfirmationProducer(undefined), []);

  const titlePDF = "Relatório de confirmação por produtor";

  return (
    <>
      {userPerfil && data?.length && resume && (
        <PDFViewer style={{ height: "84vh" }} width="100%">
          <Document
            creator="Correctors"
            title={
              meta?.typeReport === "ALL"
                ? titlePDF
                : `${data[0].produtor.name}-${handleFormatDateWithHours()}`
            }
          >
            <PageCorePDF key={resume.totalCorretor} orientation="landscape">
              {meta?.dataPayments.startDate && meta?.dataPayments.endDate && (
                <PDFCabeçalho
                  dateCurrency={FormatDateBR(GetDateUSSemValue())}
                  empresa=""
                  endDate={FormatDateBR(meta.dataPayments.endDate)}
                  hoursCurrency={HandleGetHoursCurrency()}
                  nomePdf={titlePDF}
                  startDate={FormatDateBR(meta.dataPayments.startDate)}
                />
              )}

              <View
                key={resume.totalCorretor}
                fixed
                style={{
                  borderBottom: "1px solid black",
                  marginBottom: "14px",
                }}
              >
                <ItemMapToInfoSearch
                  itens={
                    meta?.typeReport === "UNIQUE" ? [data[0].produtor.name] : []
                  }
                  title="Produtor"
                />
                <ItemMapToInfoSearch
                  itens={
                    [meta?.dataPayments.typeDate as string] || ["PAGEMENTO"]
                  }
                  title="Tipo de data"
                />
                <ItemMapToInfoSearch itens={meta?.filiais} title="Filiais" />
                <ItemMapToInfoSearch
                  itens={meta?.operadoras}
                  title="Operadoras"
                />
                <ItemMapToInfoSearch
                  itens={meta?.modalidades}
                  title="Modalidades"
                />
                <ItemMapToInfoSearch
                  itens={meta?.administradoras}
                  title="Administradoras"
                />
              </View>

              <TableBase key={resume.totalCorretor} margin="0">
                <HeadReportConfirmationProducerPDF />

                {Children.toArray(
                  data.map(({ contratos, produtor, resume }) => {
                    return (
                      <>
                        <LineItem id={produtor.id} name={produtor.name} />

                        {Children.toArray(
                          contratos.map((itemContrato) => {
                            return (
                              <TableRowContainer key={produtor.id}>
                                <PDFTableRow
                                  paddingLeft="8px"
                                  textAlign="center"
                                  title={itemContrato.numeroParcela}
                                  width="10%"
                                />
                                <PDFTableRow
                                  textAlign="center"
                                  title={FormatMoney(
                                    itemContrato.totalParcelas
                                  )}
                                  width="12%"
                                />
                                <PDFTableRow
                                  paddingLeft="8px"
                                  textAlign="right"
                                  title={FormatMoney(
                                    itemContrato.valorTotalMensalidade
                                  )}
                                  width="13%"
                                />
                                <PDFTableRow
                                  textAlign="right"
                                  title={FormatMoney(
                                    itemContrato.valorComissaoCorretora
                                  )}
                                  width="13%"
                                />

                                <PDFTableRow
                                  textAlign="right"
                                  title={FormatMoney(
                                    itemContrato.valorComissaoCorretor
                                  )}
                                  width="13%"
                                />
                                <PDFTableRow
                                  textAlign="right"
                                  title={FormatMoney(
                                    itemContrato.valorComissaoSupervisor
                                  )}
                                  width="13%"
                                />
                                <PDFTableRow
                                  textAlign="right"
                                  title={FormatMoney(
                                    itemContrato.valorComissaoGerente
                                  )}
                                  width="13%"
                                />
                                <PDFTableRow
                                  paddingRight="8px"
                                  textAlign="right"
                                  title={
                                    Number(itemContrato.valorLucroParcela) < 0
                                      ? `- ${FormatMoney(
                                          Number(
                                            itemContrato.valorLucroParcela
                                          )?.toFixed(2)
                                        )}`
                                      : FormatMoney(
                                          Number(
                                            itemContrato.valorLucroParcela
                                          )?.toFixed(2)
                                        )
                                  }
                                  width="13%"
                                />
                              </TableRowContainer>
                            );
                          })
                        )}

                        <TableRowContainer key={produtor.id}>
                          <PDFTableRow
                            fontSize={10}
                            paddingLeft="8px"
                            textAlign="center"
                            title="Total:"
                            width="10%"
                          />
                          <PDFTableRow
                            fontSize={10}
                            textAlign="center"
                            title={` ${resume.totalQtdParcelas}`}
                            width="12%"
                          />
                          <PDFTableRow
                            fontSize={10}
                            textAlign="right"
                            title={FormatMoney(
                              resume.totalMensalidade?.toFixed(2)
                            )}
                            width="13%"
                          />
                          <PDFTableRow
                            fontSize={10}
                            textAlign="right"
                            title={FormatMoney(
                              resume.totalCorretora?.toFixed(2)
                            )}
                            width="13%"
                          />
                          <PDFTableRow
                            fontSize={10}
                            textAlign="right"
                            title={FormatMoney(
                              resume.totalCorretor?.toFixed(2)
                            )}
                            width="13%"
                          />
                          <PDFTableRow
                            fontSize={10}
                            textAlign="right"
                            title={FormatMoney(
                              resume.totalSupervisor?.toFixed(2)
                            )}
                            width="13%"
                          />
                          <PDFTableRow
                            fontSize={10}
                            textAlign="right"
                            title={FormatMoney(resume.totalGerente?.toFixed(2))}
                            width="13%"
                          />
                          <PDFTableRow
                            fontSize={10}
                            paddingRight="8px"
                            textAlign="right"
                            title={
                              resume.totalLucroParcela < 0
                                ? `- ${FormatMoney(
                                    resume.totalLucroParcela?.toFixed(2)
                                  )}`
                                : FormatMoney(
                                    resume.totalLucroParcela?.toFixed(2)
                                  )
                            }
                            width="13%"
                          />
                        </TableRowContainer>
                      </>
                    );
                  })
                )}

                <TableRowContainer
                  key={resume.totalQtdParcelas}
                  backgroundColor="#eeeeee"
                >
                  <PDFTableRow
                    fontSize={11}
                    paddingLeft="8px"
                    textAlign="center"
                    title="Total:"
                    width="10%"
                  />
                  <PDFTableRow
                    fontSize={11}
                    textAlign="center"
                    title={resume.totalQtdParcelas}
                    width="12%"
                  />

                  <PDFTableRow
                    fontSize={11}
                    textAlign="right"
                    title={FormatMoney(resume.totalMensalidade.toFixed(2))}
                    width="13%"
                  />
                  <PDFTableRow
                    fontSize={11}
                    textAlign="right"
                    title={FormatMoney(resume.totalCorretora.toFixed(2))}
                    width="13%"
                  />
                  <PDFTableRow
                    fontSize={11}
                    textAlign="right"
                    title={FormatMoney(resume.totalCorretor.toFixed(2))}
                    width="13%"
                  />
                  <PDFTableRow
                    fontSize={11}
                    textAlign="right"
                    title={FormatMoney(resume.totalSupervisor.toFixed(2))}
                    width="13%"
                  />
                  <PDFTableRow
                    fontSize={11}
                    textAlign="right"
                    title={FormatMoney(resume.totalGerente.toFixed(2))}
                    width="13%"
                  />
                  <PDFTableRow
                    fontSize={11}
                    paddingRight="8px"
                    textAlign="right"
                    title={
                      resume.totalLucroParcela < 0
                        ? `- ${FormatMoney(
                            resume.totalLucroParcela?.toFixed(2)
                          )}`
                        : FormatMoney(resume.totalLucroParcela?.toFixed(2))
                    }
                    width="13%"
                  />
                </TableRowContainer>
              </TableBase>
            </PageCorePDF>
          </Document>
        </PDFViewer>
      )}

      {loadingGravar && (
        <Box style={{ width: "100%" }}>
          <LinearProgress color="inherit" />
        </Box>
      )}
    </>
  );
};
