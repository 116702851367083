import { formatString } from "@format-string/core";
import { alpha, Box, Chip } from "@mui/material";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { CardContract } from "src/Pages/components/CardContract/CardContract";
import { TitleWithValueCore } from "src/Pages/components/TitleWithValueCore/TitleWithValueCore";
import { cpfOurCnpj } from "src/shared/Utils/MaskCpfCnpj";

export const CardProposta = () => {
  const { dataGetByIdContrato } = useContextContrato();

  const handleCopy = async (text: string) => {
    await navigator.clipboard.writeText(text);
    alert("Texto copiado com sucesso!");
  };

  if (!dataGetByIdContrato) return null;

  const isTypePF = dataGetByIdContrato?.contractType === "PF";

  return (
    <CardContract
      onClick={() => handleCopy(dataGetByIdContrato?.proposalCode)}
      title={`Proposta: ${dataGetByIdContrato?.proposalCode}`}
      sxStyle={{
        background: (theme) => ` ${alpha(theme.palette.primary.main, 0.5)}`,
        borderRadius: "12px",
      }}
    >
      <Box>
        <Box mb="12px" display={"flex"}>
          <TitleWithValueCore
            isFlex
            sxStyleTitle={{ fontSize: "20px", fontWeight: "500" }}
            sxStyleValue={{ fontSize: "20px", fontWeight: "500" }}
            value={dataGetByIdContrato.holder}
          />
          <Chip
            label={
              dataGetByIdContrato.contractType === "PF"
                ? "Pessoa física"
                : "Pessoa jurídica"
            }
            sx={{
              ml: 2,
              background: (theme) => alpha(theme.palette.primary.main, 0.2),
              color: "#ffffff",
              border: `1px solid white`,
            }}
          />
        </Box>

        <TitleWithValueCore
          isFlex
          sxStyleTitle={{ fontWeight: "500" }}
          title={isTypePF ? `CPF:` : `CNPJ:`}
          value={
            isTypePF
              ? (formatString({
                  type: "cpf",
                  value: dataGetByIdContrato.cpf,
                }) as string)
              : (formatString({
                  type: "cnpj",
                  value: dataGetByIdContrato.cnpj,
                }) as string)
          }
        />
        <TitleWithValueCore
          isFlex
          sxStyleTitle={{ fontWeight: "500" }}
          title="Email:"
          value={dataGetByIdContrato.ContractContact.email}
        />
        <TitleWithValueCore
          isFlex
          sxStyleTitle={{ fontWeight: "500" }}
          title="Telefone(s):"
          value={`${cpfOurCnpj({
            text: dataGetByIdContrato.ContractContact.phone1,
            type: "phone",
          })} / ${cpfOurCnpj({
            text: dataGetByIdContrato.ContractContact.phone1,
            type: "phone",
          })}`}
        />
      </Box>
    </CardContract>
  );
};
