import { useState } from "react";
import { useContextDashboardContext } from "src/Contexts/Dashboard/ContextDashboardBroker";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

export const UseMetricsDashboard = () => {
  const { handleGetAlert } = useLayoutMainContext();

  const { filterMetricsFilterDashboard, setMetricsDashboard } =
    useContextDashboardContext();

  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);

    api
      .get(
        `/metrics?startDate=${filterMetricsFilterDashboard.startDate}&endDate=${filterMetricsFilterDashboard.endDate}`
      )
      .then((res) => setMetricsDashboard(res.data))
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  return { handleSubmit, loading };
};
