import React, { useEffect } from "react";
import { useResetInputs } from "src/Contexts";
import { ModalCore } from "src/Pages/components";

import { FormularioAdministradoras } from "./FormularioAdministradora";
import { UseFormOperadora } from "./Hook/UseFeaturesFormulario";

interface IModalAdministradoras {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalAdministradora = ({
  openModal,
  setOpenModal,
}: IModalAdministradoras) => {
  const { resetInputs } = useResetInputs();

  const { handleSubmit, loading: loadingSubmit } = UseFormOperadora({
    setOpenModal,
  });

  useEffect(() => {
    if (!openModal) resetInputs();
  }, [openModal]);

  return (
    <ModalCore
      loadingSubmit={loadingSubmit}
      open={openModal}
      setOpen={setOpenModal}
      sxStyle={{ width: "500px" }}
      title="Administradora"
      titleSubmit="Salvar"
      onChange={handleSubmit}
    >
      <FormularioAdministradoras />
    </ModalCore>
  );
};
