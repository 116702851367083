import { Box, Chip, Typography } from "@mui/material";
import { FormatMoney } from "src/shared/Utils/FormatMoney";

import { CardCore } from "src/Pages/components/CardCore/CardCore";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import moment from "moment";
moment.locale("pt-br");

export const PlanActive = () => {
  const { userPerfil } = useAuthContext();

  const objMethods = {
    PIX: "Pix",
    BOLETO: "Boleto",
    CREDIT_CARD: "Cartão de crédito",
    DEBIT_CARD: "Cartão de debito",
  };

  return (
    <CardCore
      sxStyle={{
        width: "550px",
        maxWidth: "100%",
        marginBottom: "24px",
        marginLeft: 0,
        "& > *": { fontWeight: 500 },
      }}
    >
      <Box
        sx={{
          background: (theme) => theme.custom.gradient.cardSecondary,
          borderRadius: "0 24px 24px 0",
          marginLeft: { xs: "-10px", sm: "-16px" },
          marginBottom: "16px",
          padding: "6px 14px 6px 14px",
          display: "inline-block",
        }}
      >
        <Typography
          sx={{ color: "white", fontSize: { xs: "14px", sm: "16px" } }}
        >
          Assinante desde{" "}
          {moment(userPerfil?.subscription?.startDate).format("MMMM [de] YYYY")}
        </Typography>
      </Box>

      <Box display="flex" justifyContent="space-between" mb="24px">
        <Typography
          sx={{ fontSize: { xs: "16px", md: "20px" }, fontWeight: "500" }}
        >
          Plano padrão
        </Typography>
        <Typography sx={{ fontSize: { xs: "12px", md: "20px" } }}>
          R$ {FormatMoney(userPerfil?.subscription?.Plan.amount.toFixed(2))}
        </Typography>
      </Box>

      <Box display="flex" justifyContent="space-between" mb="8px">
        <Typography>Status:</Typography>

        <Chip
          color="success"
          label={userPerfil?.subscription?.isActive ? "Ativo" : "Não ativo"}
          sx={{
            fontSize: ".8rem",
            padding: ".1rem",
            height: "24px",
          }}
          variant="outlined"
        />
      </Box>

      <Box display="flex" justifyContent="space-between" mb="8px">
        <Typography>Proxima cobrança</Typography>
        <Typography>DD-MM-AAAA</Typography>
      </Box>

      <Box display="flex" justifyContent="space-between" mb="8px">
        <Typography>Método de pagamento</Typography>
        <Typography>
          {objMethods[userPerfil?.subscription?.methodPayment as "CREDIT_CARD"]}
        </Typography>
      </Box>
    </CardCore>
  );
};
