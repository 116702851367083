import { useState } from "react";
import { useContextComissoes } from "src/Contexts/comissoes/ComissoesContext";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

interface IUseFormModalidade {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UseFormModalidade = ({ setOpenModal }: IUseFormModalidade) => {
  const { valuesInputsModalidade } = useContextComissoes();
  const { setAttTable, id } = useConfigPageContext();
  const { handleGetAlert } = useLayoutMainContext();

  const [loading, setLoading] = useState(false);

  const handleCreate = async () => {
    if (!valuesInputsModalidade.name?.trim())
      return handleGetAlert({ message: `Digite uma Modalidade` });

    setLoading(true);

    return api
      .post("/modalities", valuesInputsModalidade)
      .then((res) => {
        setAttTable((item) => !item);
        handleGetAlert({ message: res.data.message });
        setOpenModal(false);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  const handleEdit = () => {
    if (!valuesInputsModalidade.name?.trim())
      return handleGetAlert({ message: `Digite uma Modalidade` });

    setLoading(true);

    return api
      .put(`/modalities/${id}`, valuesInputsModalidade)
      .then((res) => {
        setAttTable((item) => !item);
        handleGetAlert({ message: res.data.message });
        setOpenModal(false);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  const handleSubmit = () => {
    if (id) {
      handleEdit();
    } else {
      handleCreate();
    }
  };

  return { handleSubmit, loading };
};
