import { useState } from "react";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";
import { apenasNumeros } from "src/shared/Utils/FormatMoney";

interface IUseFormFormNewInstallments {
  cod_contrato: string;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UseFormFormNewInstallments = ({
  cod_contrato,
  setOpenModal,
}: IUseFormFormNewInstallments) => {
  const { valuesInputNewInstallments } = useContextContrato();
  const { handleGetAlert } = useLayoutMainContext();
  const { setAttTable } = useConfigPageContext();

  const [loading, setLoading] = useState(false);

  const handleFormNewInstallments = () => {
    setLoading(true);

    api
      .post(`/installments/${cod_contrato}`, {
        lives: Number(valuesInputNewInstallments?.lives),
        dueDate: valuesInputNewInstallments?.dueDate,
        commission: Number(valuesInputNewInstallments?.commissionPercentage),
        installmentNumber: Number(
          valuesInputNewInstallments?.installmentNumber
        ),
        monthlyPayment: Number(
          apenasNumeros(valuesInputNewInstallments?.contractValue)
        ),
      })
      .then((res) => {
        setAttTable((item) => !item);
        handleGetAlert({ message: res.data.message });
        setOpenModal(false);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  return { handleFormNewInstallments, loading };
};
