import { ChangeEvent, createRef } from "react";
import { Box, Grid, MenuItem } from "@mui/material";
import { useResetInputs } from "src/Contexts";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextReportsProduction } from "src/Contexts/ReportProduction/ReportsProductionContextContext";
import { InputsProductionContract } from "src/Contexts/ReportProduction/ReportsProductionTypes";
import { FormCore } from "src/Pages/components";
import { AppTextField } from "src/Pages/components/AppFormComponents/AppTextField";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";
import { ComponentPrivate } from "src/Pages/components/ComponentPrivate/ComponentPrivate";

import { SelectedAdministradorasGeneric } from "../../../../../../components/AutoCompleted/Multiple/SelectedAdministradoras";
import { SelectedModalidadesGeneric } from "../../../../../../components/AutoCompleted/Multiple/SelectedModalidades";
import { SelectedOperadorasGeneric } from "../../../../../../components/AutoCompleted/Multiple/SelectedOperadoras";

import { UseReportProductionContract } from "./Hooks/UseReportProductionContract";

export const FormReportProductionContract = () => {
  const { loadingGravar } = useConfigPageContext();
  const { handleSubmit } = UseReportProductionContract();
  const { resetInputs } = useResetInputs(true);

  const { setValuesInputsProductionContract, valuesInputsProductionContract } =
    useContextReportsProduction();

  const refOrigem = createRef<HTMLInputElement>();

  const handleChange =
    (prop: keyof InputsProductionContract) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setValuesInputsProductionContract({
        ...valuesInputsProductionContract,
        [prop]: event.target.value,
      });
    };

  return (
    <FormCore sxStyle={{ margin: "0" }}>
      <Grid
        container
        sx={{
          "& > *": {
            padding: "12px",
            margin: "4px 8px 8px 4px",
            border: "1px solid gray",
            borderRadius: "8px",
          },
        }}
      >
        <Grid
          container
          sx={{
            width: { xs: "100%", sm: "270px" },
            "& > *": { marginBottom: { xs: "1.8rem", sm: "0" } },
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <AppTextField
                fullWidth
                inputRef={refOrigem}
                label="Data inicial *"
                sxStyle={{ margin: 0 }}
                type="date"
                value={valuesInputsProductionContract?.start_date}
                onChange={handleChange("start_date")}
              />
            </Grid>

            <Grid item xs={6}>
              <AppTextField
                fullWidth
                label="Data final *"
                sxStyle={{ margin: 0 }}
                type="date"
                value={valuesInputsProductionContract?.end_date}
                onChange={handleChange("end_date")}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <AppTextField
              fullWidth
              select
              id="Tipo de data"
              label="Tipo de data *"
              sxStyle={{ margin: 0 }}
              value={valuesInputsProductionContract?.type_date}
              onChange={handleChange("type_date")}
            >
              <MenuItem value="ASSINATURA">Assinatura</MenuItem>
              <MenuItem value="VIGENCIA">Vigência</MenuItem>
              <MenuItem value="EMISSAO">Emissão</MenuItem>
            </AppTextField>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            width: { xs: "100%", sm: "300px" },
            "& > *": { maxHeight: "30px", marginBottom: "1.8rem" },
          }}
        >
          <Grid item xs={12}>
            <SelectedOperadorasGeneric fullWidth label="Operadoras" />
          </Grid>
          <Grid item xs={12}>
            <SelectedModalidadesGeneric fullWidth label="Modalidades" />
          </Grid>
          <Grid item xs={12}>
            <SelectedAdministradorasGeneric fullWidth label="Administradoras" />
          </Grid>
        </Grid>

        <ComponentPrivate isView>
          <Grid container sx={{ width: { xs: "100%", sm: "280px" } }}>
            <Grid item xs={12}>
              <AppTextField
                fullWidth
                select
                id="group"
                label="Agrupar por corretor"
                sxStyle={{ margin: 0 }}
                value={valuesInputsProductionContract?.is_group}
                onChange={handleChange("is_group")}
              >
                <MenuItem value="TRUE">Sim</MenuItem>
                <MenuItem value="FALSE">Não</MenuItem>
              </AppTextField>
            </Grid>
          </Grid>
        </ComponentPrivate>

        <ComponentPrivate isView>
          <Grid
            container
            sx={{
              width: { xs: "100%", sm: "300px" },
            }}
          >
            <Grid item xs={12}>
              <AppTextField
                fullWidth
                select
                id="produtor"
                label="Produtor *"
                sxStyle={{ margin: 0 }}
                value={valuesInputsProductionContract?.type_report}
                onChange={handleChange("type_report")}
              >
                <MenuItem value="ALL">Todos</MenuItem>
                <MenuItem value="UNIQUE">Por produtor</MenuItem>
              </AppTextField>
            </Grid>
          </Grid>
        </ComponentPrivate>
      </Grid>

      <Box sx={{ display: "flex" }}>
        <ButtonCore
          disabled={loadingGravar}
          sx={{ width: "180px" }}
          title="Limpar filtros"
          onClick={resetInputs}
        />
        <ButtonCore
          fullWidth
          disabled={loadingGravar}
          title="Enviar"
          type="submit"
          onClick={handleSubmit}
        />
      </Box>
    </FormCore>
  );
};
