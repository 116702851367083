import React, { useEffect } from "react";
import { useResetInputs } from "src/Contexts";
import { ModalCore } from "src/Pages/components";

import { FormCompletionInfoUser } from "./FormCompletionInfoUser";
import { UseFormCompletionInfoUser } from "./Hook/UseFeaturesFormulario";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { formatString } from "@format-string/core";

interface IModal {
  idPlan: string;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalFormCompletionInfoUser = ({
  idPlan,
  openModal,
  setOpenModal,
}: IModal) => {
  const { resetInputs } = useResetInputs();
  const { setValuesInputsCompletionUser, userPerfil } = useAuthContext();

  const { handleSubmit, loading: loadingSubmit } = UseFormCompletionInfoUser({
    setOpenModal,
    idPlan,
  });

  useEffect(() => {
    if (!openModal) resetInputs();

    if (openModal && userPerfil) {
      setValuesInputsCompletionUser({
        cpf: "",
        name: userPerfil.name,
        phone1: formatString({ value: userPerfil.phone1, type: "phone" }) || "",
        phone2: userPerfil.phone2 || "",
      });
    }
  }, [openModal]);

  return (
    <ModalCore
      loadingSubmit={loadingSubmit}
      open={openModal}
      setOpen={setOpenModal}
      sxStyle={{ width: "500px" }}
      title="Completar informações pessoais"
      titleSubmit="Salvar"
      onChange={handleSubmit}
    >
      <FormCompletionInfoUser />
    </ModalCore>
  );
};
