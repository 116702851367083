import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { CardPlans } from "src/Pages/components/CardPlans/CardPlans";

import { handleGetAllPlansDetails } from "./CardListDetails/CardListDetails";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { ModalFormCompletionInfoUser } from "src/Pages/components/FormCompletionInfoUser";
import { useState } from "react";

export const handleRedirect = (url: string) => {
  const newWindow = window.open(url, "_blank");
  if (
    !newWindow ||
    newWindow.closed ||
    typeof newWindow.closed === "undefined"
  ) {
    window.location.href = url;
  }
};

export const CardPlan = () => {
  const { openAside } = useLayoutMainContext();
  const { userPerfil } = useAuthContext();

  const [idPlan, setIdPlan] = useState("");

  const [openModalCompletionInfo, setOpenModalCompletionInfo] =
    useState<boolean>(false);

  const theme = useTheme();

  const isBreakPoint = useMediaQuery(theme.breakpoints.down("lg"));

  const handleClick = (idPlan: string) => {
    if (!userPerfil?.isAddInfoCompleted) {
      alert("Preencher dados");
      setIdPlan(idPlan);
      setOpenModalCompletionInfo(true);
    }
  };

  return (
    <>
      <ModalFormCompletionInfoUser
        idPlan={idPlan}
        openModal={openModalCompletionInfo}
        setOpenModal={setOpenModalCompletionInfo}
      />
      <Grid container spacing={{ xs: 3, md: 2 }} sx={{ alignItems: "center" }}>
        <Grid item md={openAside && isBreakPoint ? 12 : 6} xs={12}>
          <CardPlans
            data={{
              title: "Mensal",
              extensionValue: "MONTH",
              background: theme.palette.primary.main,
              subTitle: `Plano para quem esta começando`,
              value: 39.9,
              onClick: () => handleClick("123-123-123"),
              details: handleGetAllPlansDetails().map((item) => item),
            }}
          />
        </Grid>

        <Grid item md={openAside && isBreakPoint ? 12 : 6} xs={12}>
          <CardPlans
            data={{
              title: "Anual",
              extensionValue: "YEARLY",
              recommend: true,
              background: theme.palette.primary.main,
              subTitle:
                "Economize R$79,80, dois meses de desconto no plano anual",
              value: 399.9,
              onClick: () => handleClick("222"),
              details: handleGetAllPlansDetails().map((item) => item),
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
