import { Children, useEffect, useState } from "react";

import { useContextComissoes } from "src/Contexts/comissoes/ComissoesContext";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { ModalDeleteCore, TableCore, TdCore } from "src/Pages/components";
import {
  DeleteForeverIcon,
  FolderCopyIcon,
  FolderOffIcon,
  CheckIcon,
  CloseIcon,
  EditIcon,
  AddIcon,
} from "src/Pages/components/Icons/Icons";

import { UseDelete } from "src/Pages/components/ModalDeleteCore/Hooks/UseDelete";
import { TableRowCore } from "src/Pages/components/table/@Core/TableRowCore/TableRowCore";
import { ActionPopoverTable } from "src/Pages/components/table/ActionPopover/ActionPopover";

import { useHttpTableEmpresas } from "./Hooks/useHttpTableEmpresas";
import { THeadEmpresas } from "./THead";
import { ModalEmpresas } from "../Form/indexForm";
import { ModalChangeIsActiveEmpresa } from "../ChangeIsActiveEmpresa/ModalChangeIsActiveEmpresa";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";

export const TableEmpresas = () => {
  const { listEmpresas, setValuesInputsEmpresas } = useContextComissoes();
  const { handleGet } = useHttpTableEmpresas();

  const { attTable, setId } = useConfigPageContext();

  const [openModalEmpresas, setOpenModalEmpresas] = useState(false);
  const [openModalActiveEmpresa, setOpenModalActiveEmpresa] = useState(false);
  const [valueActiveEmpresa, setValueActiveEmpresa] = useState<boolean>(true);

  const handleGetIdToEdit = (id: string, Empresa: string) => {
    setId(id);
    setOpenModalEmpresas(true);
    setValuesInputsEmpresas({ name: Empresa, isActive: true });
  };

  useEffect(() => {
    handleGet();
  }, [attTable]);

  const {
    handleDelete,
    open: openModalDelete,
    loading: loadingDelete,
    setId: setIdModalDelete,
    setOpen: setOpenModalDelete,
  } = UseDelete({ url: "/companies" });

  return (
    <TableCore
      Modals={
        <>
          <ModalEmpresas
            openModal={openModalEmpresas}
            setOpenModal={setOpenModalEmpresas}
          />
          <ModalDeleteCore
            loading={loadingDelete}
            modalOpen={openModalDelete}
            no="Não, Cancelar"
            titlePrimary="Você deseja excluir da lista ?"
            yes="Sim, Deletar !"
            onClickFalse={() => setOpenModalDelete(false)}
            onClickTrue={() => handleDelete()}
          />
          <ModalChangeIsActiveEmpresa
            isActiveEmpresa={valueActiveEmpresa}
            modalIsActiveEmpresa={openModalActiveEmpresa}
            setModalIsActiveEmpresa={setOpenModalActiveEmpresa}
          />
        </>
      }
      title="Empresas"
      isNotPagination
      heightTable="420px"
      THead={<THeadEmpresas />}
      qtdList={listEmpresas.length}
      cardAdd={{
        title: "Empresas",
        buttons: [
          <ButtonCore
            startIcon={<AddIcon />}
            title="Criar"
            size="small"
            onClick={() => setOpenModalEmpresas(true)}
          />,
        ],
      }}
    >
      {Children.toArray(
        listEmpresas.map((item) => {
          return (
            <TableRowCore id={String(item.code)}>
              <TdCore
                values={
                  <ActionPopoverTable
                    optionsList={[
                      {
                        icon: <EditIcon fontSize="small" />,
                        title: "Editar",
                        background: "warning",
                        onClick: () =>
                          handleGetIdToEdit(String(item.code), item.name),
                      },
                      {
                        icon: item.isActive ? (
                          <FolderOffIcon fontSize="small" />
                        ) : (
                          <FolderCopyIcon fontSize="small" />
                        ),
                        isDisabled: true,
                        background: "warning",
                        title: item.isActive ? "Desativar" : "Ativar",
                        onClick: () => {
                          setId(item.name);
                          setOpenModalActiveEmpresa(true);
                          setValueActiveEmpresa(!item.isActive);
                        },
                      },
                      {
                        icon: <DeleteForeverIcon fontSize="small" />,
                        title: "Excluir",
                        background: "error",
                        onClick: () => {
                          setIdModalDelete(item.code);
                          setOpenModalDelete(true);
                        },
                      },
                    ]}
                  />
                }
              />
              <TdCore values={item.name} />

              <TdCore
                textAlign="center"
                values={
                  item.isActive ? (
                    <CheckIcon color="success" />
                  ) : (
                    <CloseIcon color="error" />
                  )
                }
              />
            </TableRowCore>
          );
        })
      )}
    </TableCore>
  );
};
