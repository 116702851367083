import { FC, ReactNode } from "react";
import { alpha, TableRow } from "@mui/material";

type ITableRowCore = {
  id?: string;
  children: ReactNode;
};

export const TableRowCore: FC<ITableRowCore> = ({ id, children }) => {
  return (
    <TableRow
      key={id}
      sx={{
        "&:hover": {
          background: (theme) => alpha(theme.palette.text.primary, 0.04),
        },
      }}
    >
      {children}
    </TableRow>
  );
};
