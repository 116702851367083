import { useState } from "react";
import { useContextComissoes } from "src/Contexts/comissoes/ComissoesContext";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

interface IUseFormCommissions {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UseFormCommissions = ({ setOpenModal }: IUseFormCommissions) => {
  const { valuesInputsCommissions } = useContextComissoes();
  const { setAttTable, id } = useConfigPageContext();
  const { handleGetAlert } = useLayoutMainContext();

  const [loading, setLoading] = useState(false);

  const {
    isActive,
    effectiveDate,
    parc_1,
    parc_2,
    parc_3,
    parc_4,
    parc_5,
    parc_6,
    companyCode,
    modalityCode,
    operatorCode,
    administratorCode,
  } = valuesInputsCommissions;

  const gridCommissions = {
    effectiveDate,
    isActive,

    modalityCode: Number(modalityCode),
    operatorCode: Number(operatorCode),
    companyCode: Number(companyCode),
    administratorCode: administratorCode ? Number(administratorCode) : null,

    installments: [
      { number: 1, value: Number(parc_1) },
      { number: 2, value: Number(parc_2) },
      { number: 3, value: Number(parc_3) },
      { number: 4, value: Number(parc_4) },
      { number: 5, value: Number(parc_5) },
      { number: 6, value: Number(parc_6) },
    ],
  };

  const handleCreate = async () => {
    setLoading(true);

    return api
      .post("/commissions", { ...gridCommissions })
      .then((res) => {
        handleGetAlert({ message: res.data.message });
        setAttTable((item) => !item);
        setOpenModal(false);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  const handleEdit = async () => {
    setLoading(true);

    return api
      .put(`/commissions/${id}`, { ...gridCommissions })
      .then((res) => {
        setAttTable((item) => !item);
        handleGetAlert({ message: res.data.message });
        setOpenModal(false);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  const handleSubmit = () => {
    if (id) {
      handleEdit();
    } else {
      handleCreate();
    }
  };

  return { handleSubmit, loading };
};
