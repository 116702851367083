import { useState } from "react";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";
import { apenasNumeros } from "src/shared/Utils/FormatMoney";

interface IHandleUpdateStatusContratos {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UseFormFormNovaParcela = ({
  setOpenModal,
}: IHandleUpdateStatusContratos) => {
  const { setAttTable } = useConfigPageContext();
  const { handleGetAlert } = useLayoutMainContext();
  const { valuesInputUpdateInstallment } = useContextContrato();

  const [loading, setLoading] = useState(false);

  const handleEditarParcela = async () => {
    setLoading(true);

    await api
      .put(`/installments/${valuesInputUpdateInstallment?.id}`, {
        dueDate: valuesInputUpdateInstallment?.dueDate,
        lives: Number(valuesInputUpdateInstallment?.lives),
        commission: Number(valuesInputUpdateInstallment?.commission),
        installmentNumber: Number(
          valuesInputUpdateInstallment?.installmentNumber
        ),
        monthlyPayment: Number(
          apenasNumeros(valuesInputUpdateInstallment?.monthlyPayment)
        ),
      })
      .then((res) => {
        handleGetAlert({ message: res.data.message });
        setAttTable((item) => !item);
        setOpenModal(false);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  return { handleEditarParcela, loading };
};
