import { UrlConfig } from "src/shared/Utils/paths";

interface IDataHelp {
  title: string;
  description: string;
  id: string;
  page: {
    title: string;
    url: string;
  };

  tags?: string[];
  data?: {
    title?: string;
    // description: string;
  };
}

export const dataHelp: IDataHelp[] = [
  {
    title: "Ao criar proposta recebi alerta que não existe grade de comissão",
    page: {
      title: UrlConfig.comissao.corretora.title,
      url: UrlConfig.comissao.corretora.url,
    },
    id: "d77d93d9-65a3-45b9-8784-7bcadf6bbf36",
    description:
      "Precisa verificar se existe grade na tela de grade de comissão com a mesma operadora/modalidade e data de vigência",
  },

  {
    title: "Como cadastrar uma proposta",
    page: {
      title: UrlConfig.lancamentos.contratos.title,
      url: UrlConfig.lancamentos.contratos.url,
    },
    id: "2836ddfb-f481-4f6d-9717-1bcf573ec644",
    description:
      "Na tela de Lançamentos/contrato você pode criar/editar/deletar/cancelar. ",
  },
  {
    title:
      "Como sinalizar para o sistema que recebeu da operadora/administradora",
    page: {
      title: UrlConfig.lancamentos.parcelas.title,
      url: UrlConfig.lancamentos.parcelas.url,
    },
    id: "2e8933c8-ba53-49aa-8a67-878174ccfb79",
    description:
      "Na tela parcelas você sinaliza para o sistema que recebeu e libera para pagar os produtores.",
  },
  {
    title: "Como cadastrar a grade de comissão que recebe",
    page: {
      title: UrlConfig.comissao.corretora.title,
      url: UrlConfig.comissao.corretora.url,
    },
    id: "366082a3-61e8-4609-a2eb-dc27e9d40169",
    description:
      "Na tela de grade de comissões você pode criar grades que recebe da operadora/administradora.",
  },
  {
    title: "Como ver relatório de produção",
    page: {
      title: UrlConfig.relatoriosProducao.producaoContract.title,
      url: UrlConfig.relatoriosProducao.producaoContract.url,
    },
    id: "e5a769ff-e2f7-4754-bcc5-7930edf787bf",
    description:
      "Na tela de relatório de contrato você vai ver a produção com detalhes das propostas.",
  },
];
