import { Box, Typography } from "@mui/material";
import { TurnedInIcon } from "src/Pages/components/Icons/Icons";

export const HeaderRegister = () => {
  return (
    <Box
      sx={{
        margin: "0 0 24px 0",
        alignItems: "center",
        display: "flex",
        padding: "8px",
      }}
    >
      <TurnedInIcon
        sx={{
          color: (theme) => theme.palette.primary.main,
          marginRight: "8px",
        }}
      />
      <Typography
        sx={{
          fontSize: { xs: ".8rem", md: ".9rem", lg: "1rem" },
        }}
      >
        Otimize sua gestão, simplifique sua vida. Faça seu cadastro agora e
        ganhe acesso de 7 dias grátis !
      </Typography>
    </Box>
  );
};
