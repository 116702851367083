import { Children, useState } from "react";
import { Grid } from "@mui/material";
import { AppTextField } from "src/Pages/components";
import { CardCore } from "src/Pages/components/CardCore/CardCore";
import { CardHelp } from "src/Pages/components/CardHelp/CardHelp";
import { handleRemoveCharacterSpecial } from "src/shared/Utils/removeCharacterSpecial/removeCharacterSpecial";

import { dataHelp } from "./dataHelp";

export const CardsHelp = () => {
  const [search, setSearch] = useState("");

  const handleFilteredHelp = dataHelp.filter((item) => {
    const searchClear = handleRemoveCharacterSpecial(
      search.toLowerCase().trim()
    );

    const resultFilterTags = item.tags?.some((tag) =>
      handleRemoveCharacterSpecial(tag.toLowerCase().trim()).includes(
        searchClear
      )
    );

    const resultFilterTitle = handleRemoveCharacterSpecial(
      item.title.toLowerCase().trim()
    ).includes(searchClear);

    return resultFilterTitle || resultFilterTags;
  });

  const filteredHelp = search.length > 0 ? handleFilteredHelp : dataHelp;

  return (
    <CardCore sxStyle={{ margin: 0 }}>
      <AppTextField
        fullWidth
        label="Pesquisar"
        placeholder="Pesquisar duvidas"
        sxStyle={{
          margin: "1rem 0 2rem 0",
        }}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />

      <Grid container spacing={2}>
        {Children.toArray(
          filteredHelp.map((item) => (
            <Grid item lg={4} sm={6} xs={12}>
              <CardHelp
                description={item.description}
                id={item.id}
                page={item.page}
                title={item.title}
              />
            </Grid>
          ))
        )}
      </Grid>
    </CardCore>
  );
};
