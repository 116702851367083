import { formatString } from "@format-string/core";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { IListContratos } from "src/Contexts/Lancamentos/Contrato/ContratosTypes";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";
import { handleFormatCep } from "src/shared/Utils/FormatCEP";
import { FormatMoney } from "src/shared/Utils/FormatMoney";
import { FormatRG } from "src/shared/Utils/FormatRG/FormatRG";
import { handleFormatDateIfIsNull } from "src/shared/Utils/handleFormatDateIfIsNull";
import { cpfOurCnpj } from "src/shared/Utils/MaskCpfCnpj";

export const UseGetByIdContratos = () => {
  const { handleGetAlert } = useLayoutMainContext();

  const { setValuesInputsContrato } = useContextContrato();

  const handleGetById = async (id: string) => {
    await api
      .get<IListContratos>(`/contracts/${id}`)
      .then(({ data }) => {
        setValuesInputsContrato({
          subscriptionDate: handleFormatDateIfIsNull(data.subscriptionDate),
          contractType: data.contractType,
          proposalCode: data.proposalCode,

          companyCode: String(data.company.code),
          operatorCode: String(data.operator.code),
          modalityCode: String(data.modality.code),
          administratorCode: String(data?.administrator?.code || ""),

          guardianName: data.guardianName,
          guardianBirthDate: handleFormatDateIfIsNull(data.guardianBirthDate),
          guardianCpf: cpfOurCnpj({ text: data.guardianCpf, type: "cpf" }),

          holder: data.holder,
          motherName: data.motherName,
          maritalStatus: data.maritalStatus,
          gender: data.gender,
          rg: FormatRG(data.rg),
          birthDate: handleFormatDateIfIsNull(data.birthDate),

          cpf: cpfOurCnpj({
            text: data.cpf,
            type: data.contractType === "PF" ? "cpf" : "cnpj",
          }),

          companyName: data.companyName,
          cnpj: cpfOurCnpj({
            text: data.cnpj,
            type: data.contractType === "PF" ? "cpf" : "cnpj",
          }),

          email: data.ContractContact.email,
          phone1:
            formatString({
              type: "phone",
              value: data.ContractContact.phone1,
            }) || "",
          phone2:
            formatString({
              type: "phone",
              value: data.ContractContact.phone2,
            }) || "",

          postalCode: handleFormatCep(data.ContractAddress.postalCode),
          number: data.ContractAddress.number ?? "",
          city: data.ContractAddress.city,
          neighborhood: data.ContractAddress.neighborhood,
          state: data.ContractAddress.state,
          street: data.ContractAddress.street,
          complement: data.ContractAddress.complement ?? "",

          firstInstallmentDueDate: handleFormatDateIfIsNull(
            data.firstInstallmentDueDate
          ),
          startDate: handleFormatDateIfIsNull(data.startDate),
          issuanceDate: handleFormatDateIfIsNull(data.issuanceDate),
          expirationDate: handleFormatDateIfIsNull(data.expirationDate),

          monthlyPayment: FormatMoney(data.monthlyPayment),

          lives: String(data.lives),

          notes: data.notes,
          commissionGrade: "",
        });
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      );
  };

  return { handleGetById };
};
