import { ChangeEvent, createRef } from "react";
import { Box, Grid, MenuItem } from "@mui/material";
import { useResetInputs } from "src/Contexts";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextReportsProduction } from "src/Contexts/ReportProduction/ReportsProductionContextContext";
import { InputsConfirmationContract } from "src/Contexts/ReportProduction/ReportsProductionTypes";
import { FormCore } from "src/Pages/components";
import { AppTextField } from "src/Pages/components/AppFormComponents/AppTextField";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";
import { ComponentPrivate } from "src/Pages/components/ComponentPrivate/ComponentPrivate";

import { SelectedAdministradorasGeneric } from "../../../../../../components/AutoCompleted/Multiple/SelectedAdministradoras";
import { SelectedModalidadesGeneric } from "../../../../../../components/AutoCompleted/Multiple/SelectedModalidades";
import { SelectedOperadorasGeneric } from "../../../../../../components/AutoCompleted/Multiple/SelectedOperadoras";

import { UseReportConfirmationProducer } from "./Hooks/UseReportConfirmationProducer";

export const FormReportConfirmationProducer = () => {
  const { loadingGravar } = useConfigPageContext();
  const { handleSubmit } = UseReportConfirmationProducer();
  const { resetInputs } = useResetInputs(true);

  const { setInputsConfirmationProducer, inputsConfirmationProducer } =
    useContextReportsProduction();

  const refOrigem = createRef<HTMLInputElement>();

  const handleChange =
    (prop: keyof InputsConfirmationContract, option?: "limitNumber") =>
    (event: ChangeEvent<HTMLInputElement>) => {
      if (option === "limitNumber") {
        if (event.target.value.length <= 5) {
          setInputsConfirmationProducer({
            ...inputsConfirmationProducer,
            [prop]: event.target.value,
          });
        }
      } else {
        setInputsConfirmationProducer({
          ...inputsConfirmationProducer,
          [prop]: event.target.value,
        });
      }
    };

  return (
    <FormCore sxStyle={{ margin: "0" }}>
      <Grid
        container
        sx={{
          "& > *": {
            padding: "12px",
            margin: "4px 8px 8px 4px",
            border: "1px solid gray",
            borderRadius: "8px",
          },
        }}
      >
        <Grid container sx={{ width: { xs: "100%", sm: "280px" } }}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <AppTextField
                fullWidth
                inputRef={refOrigem}
                label="Data de pagamento"
                sxStyle={{ margin: 0 }}
                type="date"
                value={inputsConfirmationProducer?.start_date_payment}
                onChange={handleChange("start_date_payment")}
              />
            </Grid>
            <Grid item xs={6}>
              <AppTextField
                fullWidth
                label="Data de pagamento"
                sxStyle={{ margin: 0 }}
                type="date"
                value={inputsConfirmationProducer?.end_date_payment}
                onChange={handleChange("end_date_payment")}
              />
            </Grid>
            <Grid item xs={6}>
              <AppTextField
                fullWidth
                inputRef={refOrigem}
                label="Data de assinatura"
                sxStyle={{ margin: 0 }}
                type="date"
                value={inputsConfirmationProducer?.start_date_signature}
                onChange={handleChange("start_date_signature")}
              />
            </Grid>

            <Grid item xs={6}>
              <AppTextField
                fullWidth
                label="Data de assinatura"
                sxStyle={{ margin: 0 }}
                type="date"
                value={inputsConfirmationProducer?.end_date_signature}
                onChange={handleChange("end_date_signature")}
              />
            </Grid>
            <Grid item xs={6}>
              <AppTextField
                fullWidth
                inputRef={refOrigem}
                label="Data de vencimento"
                sxStyle={{ margin: 0 }}
                type="date"
                value={inputsConfirmationProducer?.start_date_due}
                onChange={handleChange("start_date_due")}
              />
            </Grid>
            <Grid item xs={6}>
              <AppTextField
                fullWidth
                label="Data de vencimento"
                sxStyle={{ margin: 0 }}
                type="date"
                value={inputsConfirmationProducer?.end_date_due}
                onChange={handleChange("end_date_due")}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            width: { xs: "100%", sm: "300px" },
            "& > *": { maxHeight: "30px", marginBottom: "1.8rem" },
          }}
        >
          <Grid item xs={12}>
            <SelectedOperadorasGeneric fullWidth label="Operadoras" />
          </Grid>
          <Grid item xs={12}>
            <SelectedModalidadesGeneric fullWidth label="Modalidades" />
          </Grid>
          <Grid item xs={12}>
            <SelectedAdministradorasGeneric fullWidth label="Administradoras" />
          </Grid>
        </Grid>

        <ComponentPrivate isView>
          <Grid
            container
            sx={{
              width: { xs: "100%", sm: "300px" },
            }}
          >
            <Grid item xs={12}>
              <AppTextField
                fullWidth
                select
                id="produtor"
                label="Produtor *"
                sxStyle={{ margin: 0 }}
                value={inputsConfirmationProducer?.type_report}
                onChange={handleChange("type_report")}
              >
                <MenuItem value="ALL">Todos</MenuItem>
                <MenuItem value="UNIQUE">Por produtor</MenuItem>
              </AppTextField>
            </Grid>
          </Grid>
        </ComponentPrivate>
      </Grid>

      <Box sx={{ display: "flex" }}>
        <ButtonCore
          disabled={loadingGravar}
          sx={{ width: "180px" }}
          title="Limpar filtros"
          onClick={resetInputs}
        />
        <ButtonCore
          fullWidth
          disabled={loadingGravar}
          title="Enviar"
          type="submit"
          onClick={handleSubmit}
        />
      </Box>
    </FormCore>
  );
};
