export const UrlConfig = {
  dashboard: {
    title: "Dashboard",
    url: "/dashboard",
  },

  plans: {
    title: "Pagamentos",
    url: "/payments",
    subsTitle: "Aqui você pode configurar seus pagamentos",
  },
  profile: {
    title: "Perfil",
    url: "/profile",
    subsTitle: "Aqui você pode configurar seu perfil",
  },

  help: {
    title: "Duvidas/suporte",
    url: "/help",
  },

  config: {
    title: "Configurações",
    url: "/config/details",
  },

  relatoriosProducao: {
    title: "Relatórios",

    producaoContract: {
      url: "/report/productionContract",
      title: "Produção contrato",
    },
    producaoProducer: {
      url: "/report/productionProducer",
      title: "Produção corretor",
    },
    producaoOperator: {
      url: "/report/productionOperator",
      title: "Produção operadora",
    },

    confirmationProducer: {
      url: "/report/confirmationProducer",
      title: "Confirmação produtor",
    },
  },

  comissao: {
    title: "Comissões",

    corretora: {
      url: "/Comissoes/grades",
      title: "Grade de comissões",
    },
    cadastros: {
      url: "/Comissoes/cadastros",
      title: "Cadastros",
    },
  },

  leads: {
    title: "Leads",
    leads: {
      url: "leads",
      title: "leads",
    },
  },

  lancamentos: {
    title: "Lançamentos",

    contratos: {
      url: "/Lancamentos/contratos",
      title: "Contratos",
    },

    contrato: {
      url: "/Lancamentos/contrato/:id",
      title: "Contrato interno",
    },
    contratoParcelasInterno: {
      url: "/Lancamentos/contrato/parcelas/:id",
      title: "Parcelas contrato",
    },

    parcelas: {
      url: "/Lancamentos/parcelas",
      title: "Parcelas",
    },
  },
};
