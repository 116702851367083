import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { IGetInstallments } from "src/Contexts/Lancamentos/Contrato/ContratosTypes";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

interface IHandleGet {
  currentPage?: number | undefined;
  itensPerPage?: number | undefined;
  nameSearch?: string | undefined;
  contractId?: string | undefined;
  codProposta?: string | undefined;
  codeOperadora?: string | undefined;

  startDateSignature?: string | undefined;
  endDateSignature?: string | undefined;

  startDueDate?: string | undefined;
  endDueDate?: string | undefined;

  startDateEmission?: string | undefined;
  endDateEmission?: string | undefined;

  startDateReceipt?: string | undefined;
  endDateReceipt?: string | undefined;
  installmentNumber?: string | undefined;

  installmentReceipt?: "TRUE" | "FALSE" | "ALL" | undefined;

  isInstallmentBroker?: "TRUE" | "FALSE" | "ALL" | undefined;
  isInstallmentManager?: "TRUE" | "FALSE" | "ALL" | undefined;

  client?: {
    nameOrRazao?: string | undefined;
    cpfCnpj?: string | undefined;
  };
}

export const useHttpTableBordero = () => {
  const { handleGetAlert } = useLayoutMainContext();
  const { setGetInstallments } = useContextContrato();

  const { setQtdRegisters, setLoadingTable } = useConfigPageContext();

  const handleGet = async (dataFilter: IHandleGet) => {
    setLoadingTable(true);

    api
      .get<IGetInstallments>(
        `/installments?contractId=${dataFilter.contractId}&page=${dataFilter.currentPage}&pageSize=${dataFilter.itensPerPage}&filter=${dataFilter.nameSearch}`
      )
      .then((data) => {
        setGetInstallments(data.data);
        setQtdRegisters(data.data.meta.total);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoadingTable(false));
  };

  return { handleGet };
};
