import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";
import { BasePageLogin } from "../components/BasePageLogin";

import { FooterRegister } from "./components/Footer";
import { FormLoginRegister } from "./components/Form/FormRegister";
import { UseFormRegister } from "./components/Form/Hooks/UseFormRegister";
import { HeaderRegister } from "./components/Header";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { CircularProgress } from "@mui/material";

export const PageLoginRegister = () => {
  const { handleSubmit } = UseFormRegister();
  const { loadingLogin } = useAuthContext();

  return (
    <BasePageLogin>
      <HeaderRegister />

      <FormLoginRegister />

      <ButtonCore
        fullWidth
        disabled={loadingLogin}
        title="Cadastre-se"
        type="submit"
        onClick={handleSubmit}
      >
        {loadingLogin && (
          <CircularProgress size={20} sx={{ color: "white", ml: 4 }} />
        )}
      </ButtonCore>

      <FooterRegister />
    </BasePageLogin>
  );
};
