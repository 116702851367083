import { useState } from "react";
import { Box, CardMedia, Typography } from "@mui/material";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { toAbsoluteUrl } from "src/shared/setup/toAbsoluteUrl";

import { ButtonCore } from "../ButtonCore/ButtonCore";
import { ModalContentCore } from "../ModalCoreBase/Components/ModalContentCore";
import { ModalCoreBase } from "../ModalCoreBase/ModalCoreBase";

import { UseModalFirstLogin } from "./Hooks/UseModalWelcome";

export const ModalFirstLogin = () => {
  const { userPerfil } = useAuthContext();
  const { handleIsFirstAccess } = UseModalFirstLogin();

  const [open, setOpen] = useState<boolean>(
    // !!userPerfil?.is_first_access
    false
  );

  return (
    <ModalCoreBase open={open}>
      <ModalContentCore
        sx={{
          width: { xs: "340px", sm: "500px", md: "700px" },
          maxWidth: "95vw",
          background: "#ffffff",
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: ".9rem", sm: "1.1rem", fontWeight: "500" },
            textAlign: "center",
          }}
        >
          Seja bem-vindo ao Correctors 🚀
        </Typography>

        <Box sx={{ margin: "16px 0 " }}>
          Olá {userPerfil?.name}! Para começar, explore as funcionalidades
          intuitivas que simplificarão sua gestão de comissões. Não hesite em
          nos contatar se precisar de suporte ou tiver alguma dúvida. Estamos
          aqui para tornar sua experiência excepcional!✨
        </Box>

        <CardMedia
          alt="Imagem de boas vindas"
          component="img"
          image={toAbsoluteUrl("/media/projectManagement.png")}
          sx={{ width: 151, textAlign: "center", margin: "1rem auto" }}
        />

        <ButtonCore
          sx={{
            display: "flex",
            margin: "0 auto",
            width: { xs: "150px", sm: "200px", md: "280px" },
          }}
          title="Vamos lá "
          onClick={() => {
            setOpen(false);
            handleIsFirstAccess({ idTenant: userPerfil?.idTenant });
          }}
        />
      </ModalContentCore>
    </ModalCoreBase>
  );
};
