import { FC, ReactNode } from "react";
import { CardMedia, SxProps } from "@mui/material";
import { FormCore } from "src/Pages/components";
import { toAbsoluteUrl } from "src/shared/setup/toAbsoluteUrl";

interface IBasePageLogin {
  sxStyle?: SxProps;
  children: ReactNode;
}

export const BasePageLogin: FC<IBasePageLogin> = ({ children, sxStyle }) => {
  return (
    <FormCore
      sxStyle={{
        padding: { xs: "0" },
        margin: { sm: "0 auto" },
        alignItems: "center",
        width: { xs: "100%", lg: "500px" },
        ...sxStyle,
      }}
    >
      <CardMedia
        alt="Imagem de boas vindas"
        component="img"
        image={toAbsoluteUrl("/media/logos/CORRECTORS (Apresentação).svg")}
        sx={{ width: "180px", height: "120px", mb: "1rem" }}
      />
      {children}
    </FormCore>
  );
};
