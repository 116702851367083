import { createContext, useContext, useState, FC, ReactNode } from "react";

import { IAutoCompleted } from "../types/ProdutoresContext.Types";

import {
  IReportsConfirmationProducer,
  IReportsProductionOperator,
  InputsConfirmationContract,
  IReportsProductionContract,
  InputsConfirmationProducer,
  IReportsProductionProducer,
  IContextReportsProduction,
  InputsProductionContract,
  InputsProductionOperator,
  IReportsConfirmation,
} from "./ReportsProductionTypes";
import {
  InputsDefaultReportsConfirmationContract,
  InputsDefaultReportsConfirmationProducer,
  valuesDefaultInputsReportsProduction,
  valuesDefaultInputsReportsOperator,
} from "./ValuesDefaultInputsReportProduction/ValuesDefaultInputsReportProduction";

const RecibosContext = createContext({} as IContextReportsProduction);

export const ReportsProductionContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [valueAutoCompOperadoras, setValueAutoCompOperadoras] = useState<
    IAutoCompleted[]
  >([]);

  const [valueAutoCompAdministradoras, setValueAutoCompAdministradoras] =
    useState<IAutoCompleted[]>([]);

  const [valueAutoCompModalidades, setValueAutoCompModalidades] = useState<
    IAutoCompleted[]
  >([]);

  // PRODUÇÃO por contrato - START
  const [valuesInputsProductionContract, setValuesInputsProductionContract] =
    useState<InputsProductionContract>(valuesDefaultInputsReportsProduction);

  const [valuesInputsProductionOperator, setValuesInputsProductionOperator] =
    useState<InputsProductionOperator>(valuesDefaultInputsReportsOperator);

  const [listReportProductionContract, setListReportProductionContract] =
    useState<IReportsProductionContract>();

  const [listReportProductionProducer, setListReportProductionProducer] =
    useState<IReportsProductionProducer>();

  const [listReportProductionOperator, setListReportProductionOperator] =
    useState<IReportsProductionOperator>();

  // PRODUÇÃO por contrato - END

  // CONFIRMAÇÃO por contrato - START
  const [listReportConfirmationContract, setListReportConfirmationContract] =
    useState<IReportsConfirmation>();

  const [inputsConfirmationContract, setInputsConfirmationContract] =
    useState<InputsConfirmationContract>(
      InputsDefaultReportsConfirmationContract
    );
  // CONFIRMAÇÃO por contrato - END

  // CONFIRMAÇÃO por produtor - START
  const [listReportConfirmationProducer, setListReportConfirmationProducer] =
    useState<IReportsConfirmationProducer>();

  const [inputsConfirmationProducer, setInputsConfirmationProducer] =
    useState<InputsConfirmationProducer>(
      InputsDefaultReportsConfirmationProducer
    );
  // CONFIRMAÇÃO por produtor - END

  return (
    <RecibosContext.Provider
      value={{
        setListReportConfirmationProducer,
        setListReportConfirmationContract,
        setValuesInputsProductionContract,
        setValuesInputsProductionOperator,
        setValueAutoCompAdministradoras,
        setListReportProductionContract,
        setListReportProductionProducer,
        setListReportProductionOperator,
        valuesInputsProductionOperator,
        listReportConfirmationProducer,
        listReportConfirmationContract,
        valuesInputsProductionContract,
        setInputsConfirmationContract,
        setInputsConfirmationProducer,
        listReportProductionOperator,
        listReportProductionContract,
        listReportProductionProducer,
        valueAutoCompAdministradoras,
        setValueAutoCompModalidades,
        inputsConfirmationProducer,
        inputsConfirmationContract,
        setValueAutoCompOperadoras,
        valueAutoCompModalidades,
        valueAutoCompOperadoras,
      }}
    >
      {children}
    </RecibosContext.Provider>
  );
};

export const useContextReportsProduction = (): IContextReportsProduction =>
  useContext(RecibosContext);
