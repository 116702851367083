import { Box, Grid } from "@mui/material";
import { useContextDashboardContext } from "src/Contexts/Dashboard/ContextDashboardBroker";
import { CardTable } from "src/Pages/components/table/Cards/CardTable";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import { FormatMoney } from "src/shared/Utils/FormatMoney";

export const handle = (value?: number | string) => {
  return (
    <Box sx={{ padding: "2px 12px", display: "flex", textAlign: "center" }}>
      {Number(value) >= 0 ||
      value === "Crescimento infinito" ||
      value === "Sem variação" ? (
        <TrendingUpIcon
          fontSize="large"
          sx={{ color: (theme) => theme.custom.color.money }}
        />
      ) : (
        <TrendingDownIcon fontSize="large" sx={{ color: "#ff2f40" }} />
      )}
    </Box>
  );
};

export const Cards = () => {
  const { metricsDashboard } = useContextDashboardContext();
  const {
    meta,
    contracts,
    installmentReceived,
    installmentNotReceived,
    installmentReceivedAmount,
    installmentNotReceivedAmount,
    installmentTotalValueReceived,
  } = metricsDashboard;

  return (
    <Grid container mb="16px" spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        <CardTable
          icon={{ color: "#54b880", icon: <AttachMoneyIcon /> }}
          title="Valor recebido"
          value1={FormatMoney(installmentReceivedAmount?.count || "0,00")}
          value2={`${installmentReceivedAmount?.percentLast}% últimos ${meta?.countDaysLast} dias`}
          arrow={handle(installmentReceivedAmount?.percentLast)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CardTable
          icon={{ color: "#54b880", icon: <AttachMoneyIcon /> }}
          title="Valor a receber"
          value1={FormatMoney(installmentNotReceivedAmount?.count || "0,00")}
          value2={`${installmentNotReceivedAmount?.percentLast}% últimos ${meta?.countDaysLast} dias`}
          arrow={handle(installmentNotReceivedAmount?.percentLast)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CardTable
          icon={{ color: "#54b880", icon: <AttachMoneyIcon /> }}
          title="Total previsto"
          value1={FormatMoney(installmentTotalValueReceived?.count || "0,00")}
          value2={`${installmentTotalValueReceived?.percentLast}% últimos ${meta?.countDaysLast} dias`}
          arrow={handle(installmentTotalValueReceived?.percentLast)}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <CardTable
          icon={{ color: "#0066ccb5", icon: <FileCopyIcon /> }}
          title="Propostas criadas"
          value1={String(contracts?.count)}
          value2={`${contracts?.percentLast}% últimos ${meta?.countDaysLast} dias`}
          arrow={handle(contracts?.percentLast)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CardTable
          icon={{ color: "#0066ccb5", icon: <FileCopyIcon /> }}
          title="Parcelas recebidas"
          value1={String(installmentReceived?.count)}
          value2={`${installmentReceived?.percentLast}% últimos ${meta?.countDaysLast} dias`}
          arrow={handle(installmentReceived?.percentLast)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CardTable
          icon={{ color: "#0066ccb5", icon: <FileCopyIcon /> }}
          title="Parcelas em aberto"
          value1={String(installmentNotReceived?.count)}
          value2={`${installmentNotReceived?.percentLast} últimos ${meta?.countDaysLast} dias`}
          arrow={handle(installmentNotReceived?.percentLast)}
        />
      </Grid>
    </Grid>
  );
};
