import { formatString } from "@format-string/core";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { CardContract } from "src/Pages/components/CardContract/CardContract";
import { TitleWithValueCore } from "src/Pages/components/TitleWithValueCore/TitleWithValueCore";
import { handleFormatCep } from "src/shared/Utils/FormatCEP";
import { FormatDateBR } from "src/shared/Utils/FormatDateBR";
import { handleFormatDateIfIsNull } from "src/shared/Utils/handleFormatDateIfIsNull";

export const CardDadosInformaçõesContato = () => {
  const { dataGetByIdContrato } = useContextContrato();

  if (!dataGetByIdContrato) return null;

  const {
    rg,
    cpf,
    cnpj,
    holder,
    gender,
    birthDate,
    motherName,
    guardianCpf,
    guardianName,
    contractType,
    maritalStatus,
    guardianBirthDate,
    ContractAddress: {
      city,
      state,
      street,
      number,
      complement,
      postalCode,
      neighborhood,
    },
    ContractContact: { email, phone1, phone2 },
  } = dataGetByIdContrato;

  return (
    <>
      <CardContract title="Dados do Titular">
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="Titular"
          value={holder}
        />

        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title={contractType === "PF" ? `CPF` : `CNPJ`}
          value={
            contractType === "PF"
              ? (formatString({
                  type: "cpf",
                  value: cpf,
                }) as string)
              : (formatString({
                  type: "cnpj",
                  value: cnpj,
                }) as string)
          }
        />
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="RG"
          value={rg}
        />
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="Data de nascimento"
          value={FormatDateBR(handleFormatDateIfIsNull(birthDate))}
        />
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="Estado Civil"
          value={maritalStatus.toUpperCase()}
        />
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="Gênero"
          value={gender}
        />
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="Nome da mãe"
          value={motherName}
        />
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="Nome do responsável"
          value={guardianName}
        />
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="CPF do responsável"
          value={guardianCpf}
        />
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="Data nascimento do respo."
          value={FormatDateBR(handleFormatDateIfIsNull(guardianBirthDate))}
        />
      </CardContract>

      <CardContract>
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="Email"
          value={email}
        />
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title={`Telefone(s)`}
          value={` ${
            formatString({ type: "phone", value: phone1 }) as string
          } / ${formatString({ type: "phone", value: phone2 }) as string}`}
        />
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="CEP"
          value={`${handleFormatCep(postalCode)}`}
        />

        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="Endereço"
          value={street}
        />
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="Número"
          value={number}
        />
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="UF"
          value={state}
        />
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="Cidade"
          value={city}
        />
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="Bairro"
          value={neighborhood}
        />
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="Complemento"
          value={complement}
        />
      </CardContract>
    </>
  );
};
