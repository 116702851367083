import { useState } from "react";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useResetInputs } from "src/Contexts/hooks/useResetInputs";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { ModalDeleteCore } from "src/Pages/components";
import { api } from "src/shared/setup/API/api";

interface IModalChangeIsActiveEmpresa {
  modalIsActiveEmpresa: boolean;
  setModalIsActiveEmpresa: React.Dispatch<React.SetStateAction<boolean>>;
  isActiveEmpresa: boolean;
}

export const ModalChangeIsActiveEmpresa = ({
  setModalIsActiveEmpresa,
  modalIsActiveEmpresa,
  isActiveEmpresa,
}: IModalChangeIsActiveEmpresa) => {
  const { setAttTable, id } = useConfigPageContext();
  const { handleGetAlert } = useLayoutMainContext();
  const { resetInputs } = useResetInputs();

  const [loading, setLoading] = useState(false);

  const handleIsActiveEmpresa = async () => {
    if (!id)
      return handleGetAlert({ message: "Escolha uma linha para editar" });

    setLoading(true);

    return api
      .patch(`/comissoes/Empresa/status/${id}`, {
        is_active: isActiveEmpresa,
      })
      .then((res) => {
        handleGetAlert({ message: res.data.message });
        setAttTable((item) => !item);
        resetInputs();
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => {
        setModalIsActiveEmpresa(false);
        setLoading(false);
      });
  };

  return (
    <ModalDeleteCore
      loading={loading}
      modalOpen={modalIsActiveEmpresa}
      no="Não, Cancelar"
      titlePrimary={`Você deseja ${
        !isActiveEmpresa ? "DESATIVAR" : "ATIVAR"
      } Empresa ?`}
      titleSecondary={
        !isActiveEmpresa
          ? "Empresa não será REMOVIDA ! Apenas será desativado para uso do sistema."
          : "Empresa ficará disponível para uso do sistema."
      }
      yes={`Sim, ${!isActiveEmpresa ? "Desativar" : "Ativar"} !`}
      onClickFalse={() => setModalIsActiveEmpresa(false)}
      onClickTrue={() => handleIsActiveEmpresa()}
    />
  );
};
